import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "anchor", "container", "navigation" ]
  static values = { threshold: Number }

  initialize() {
    this.lastScrollTop = window.scrollY
    this.lastDirection = null
    this.handleScroll = this._handleScroll.bind(this)
  }

  connect() {
    window.addEventListener('scroll', this.handleScroll)
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  _handleScroll() {
    const position = window.scrollY
    const direction = this.lastScrollTop > position ? 'up' : 'down'
    this.lastScrollTop = position

    if (direction === 'down' && position < this.thresholdValue) return

    if (direction !== this.lastDirection) {
      this.lastDirection = direction
      if (direction === 'up') {
        this.containerTarget.classList.remove('header--hide')
        document.body.classList.add('has-header-visible')
      } else {
        this.containerTarget.classList.add('header--hide')
        document.body.classList.remove('has-header-visible')
      }
    }
  }

  get headerBar() {
    return this.element.querySelector('.header__bar')
  }

  get promoBar() {
    return this.element.querySelector('.promo-bar')
  }

  get headerBarOpenClass() {
    return 'header__bar--opened'
  }
}
