import { Controller } from "stimulus"
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js'

import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin/dist/photoswipe-dynamic-caption-plugin.esm.min.js';

export default class extends Controller {

  static targets = [ "item", "container" ]

  connect() {
    // PhotoSwipe requires width and height do be declared.
    // If we know the width and height of the original (not resized)
    // image, use that. Otherwise, we can work around the limitation.
    this.itemTargets.forEach( item => {
      let img = item.querySelector('img')

      if (img.dataset.originalWidth && img.dataset.originalHeight) {
        item.dataset.pswpWidth = img.dataset.originalWidth
        item.dataset.pswpHeight = img.dataset.originalHeight
      } else {
        const preloadImage = new Image()
        preloadImage.src = item.getAttribute('href')

        preloadImage.onload = () => {
          item.dataset.pswpWidth = preloadImage.width
          item.dataset.pswpHeight = preloadImage.height
        }
      }
    })

    let lightbox = new PhotoSwipeLightbox({
      gallery: this.containerTarget,
      children: 'a',
      zoom: false,
      pswpModule: PhotoSwipe,
			paddingFn: (viewportSize) => {
				return {
					top: 30, bottom: 30, left: 70, right: 70
				}
			},

    });

		let captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
			// Plugins options, for example:
			type: 'auto',
		});



    lightbox.init();
  }
}
