import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		if (this.toolbar) this.toolbar.style.display = "none"
	}

	cancel() {
		// we need to check for a comments item and then look inside for a tools element
		// only if we find them we will make the tools visible
		if (this.toolbar) this.toolbar.style.display = "block"

		// then we remove the element from screen
		this.element.remove()
	}

	get toolbar() {
		if (!this.comment) return

		return this.comment.querySelector(".comments__item-tools")
	}

	get comment() {
		return this.element.closest(".comments__item")
	}
}
