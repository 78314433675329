import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item", "autoplay" ]
  static values = {
    triggerClick: Boolean,
		jumpActive: Boolean, default: false
  }
  static classes = [ "live", "pastEl", "nowEl", "futureEl", "trigger" ]

  initialize() {
    this.startTime = new Date()
    this.timeDifference = 0
    this.timer = null
    this.startedEvents = []
    this.endedEvents = []
    this.cookieName = "calendar_disable_auto_play"
    this.autoPlayDisabled = this.autoPlaySetting
  }

  connect() {
    this._startTimer()

		if (this.hasJumpActiveValue) {
			let liveEvents = document.querySelectorAll('.' + this.liveClass)

			if (liveEvents.length > 0) {
				liveEvents[0].scrollIntoView({ behavior: 'smooth' })
			}
		}
  }

  disconnect() {
    this._stopTimer()
  }

  toggleAutoPlay() {
    if( this.autoPlayDisabled == false ) {
      this._disableAutoPlay()
    } else {
      this._enableAutoPlay()
    }
  }

  _startTimer() {
    this.timer = setInterval(() => {
      this._getTimeDifference()
      this._getUpcomingEvents()
      this._getPastEvents()
      this._updateEvents()
    }, 20000)
  }

  _stopTimer() {
    clearInterval(this.timer)
  }

  _getUpcomingEvents() {
    let self = this
    this.startedEvents = [...this.itemTargets].filter(function (e) {
      return e.dataset.timeToStart <= self.timeDifference && e.dataset.timeToStart > 0
    })
  }

  _getPastEvents() {
    let self = this
    this.endedEvents = [...this.itemTargets].filter(function (e) {
      return e.dataset.timeToEnd <= self.timeDifference  && e.dataset.timeToEnd > 0
    })
  }

  _updateEvents() {
    if( this.startedEvents.length > 0 ) {
      this.startedEvents.forEach((el) => {
        el.classList.add(this.liveClass)
        el.dataset.timeToStart = 0
        el.querySelectorAll(`.${this.nowElClass}`).forEach(el => el.classList.remove('hidden'))
        el.querySelectorAll(`.${this.futureElClass}`).forEach(el => el.classList.add('hidden'))

        if ( this.hasTriggerClickValue && this.autoPlayDisabled == false ) {
          el.querySelector(`.${this.triggerClass}`).click()
        }
      })

    }

    if( this.endedEvents.length > 0 ) {
      this.endedEvents.forEach((el) => {
        el.classList.remove(this.liveClass)
        el.dataset.timeToEnd = 0
        el.querySelectorAll(`.${this.nowElClass}`).forEach(el => el.classList.add('hidden'))
        el.querySelectorAll(`.${this.pastElClass}`).forEach(el => el.classList.remove('hidden'))
      })
    }

    this.startedEvents = []
    this.endedEvents = []
  }

  _getTimeDifference() {
    let now = new Date()
    let difference = now - this.startTime
    difference /= 1000

    this.timeDifference = Math.round(difference)
  }

  _enableAutoPlay() {
    this.autoPlayDisabled = false
    document.cookie = this.cookieName+'=;Max-Age=-99999999;path=/;'
  }

  _disableAutoPlay() {
    this.autoPlayDisabled = true

    const d = new Date()
    d.setTime(d.getTime() + (24*60*60*1000))
    let expires = "expires="+ d.toUTCString()
    document.cookie = this.cookieName+"=" + true + ";" + expires + ";path=/"
  }

  get autoPlaySetting() {
    let name = this.cookieName + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return false
  }
}
