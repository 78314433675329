import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "fullPayment", "parcelsPayment", "quantity", "chargeMethod", "feedback", "submit" ]
  static values = { full: Number, parcels: Number, errorMessage: String }

  initialize() {
    this.onQuantityChange = this._updatePrice.bind(this)
    this.feedbackPreviousContent = null
  }

  connect() {
    if (this.element.tagName !== "FORM") {
      console.error("This controller can only be used in a <form> tag")
      return
    }

    this._updatePrice()
    this._displayChargeMethods()
    this.quantityTarget.addEventListener("quantity:change", this.onQuantityChange)
  }

  disconnect() {
    this.quantityTarget.removeEventListener("quantity:change", this.onQuantityChange)
  }

  onFormChange(event) {
    this._validateField(event.target)
  }

  onSubmit(event) {
    const isValid = this.element.checkValidity()
    if (isValid) {
      this.submitTarget.setAttribute("disabled", true)
      this.element.submit()
    } else {
      event.preventDefault()
    }

    this._validateAllFields()
    this._showFeedback()
  }

  onPaymentOptionChange() {
    this._displayChargeMethods()
  }

  _showFeedback() {
    if (!this.hasFeedbackTarget) return

    const feedbackContent = !this.element.checkValidity() ? this.errorMessageValue : null
    if (feedbackContent !== this.feedbackPreviousContent) {
      this.feedbackTarget.innerText = feedbackContent
      this.feedbackPreviousContent = feedbackContent
    }
  }

  _validateAllFields() {
    const formElements = this.element.elements
    Array.from(formElements).forEach((element) => this._validateField(element))
  }

  _validateField(field) {
    // we will get closest `.thu-form__field` class and only do something if we have one
    const fieldWrapper = field.closest(".thu-form__field")
    if (!fieldWrapper) return

    fieldWrapper.classList.toggle("is-invalid", !field.checkValidity())
  }

  _displayChargeMethods() {
    if (this.hasChargeMethodTarget) this.chargeMethodTarget.classList.toggle("hidden", this.isFullPayment)
  }

  _updatePrice() {
    this._updateAmount(this.fullPaymentTarget, this.fullValue)
    if (this.hasParcelsPaymentTarget) this._updateAmount(this.parcelsPaymentTarget, this.fullValue / this.parcelsValue)
  }

  _updateAmount(source, value) {
    const quantity = parseInt(this.quantityTarget.value)
    const element = source.querySelector("[data-currency-amount-value]")
    element.dataset.currencyAmountValue = value * quantity
  }

  get isFullPayment() {
    return this.fullPaymentTarget.querySelector('input[type="radio"]').checked
  }
}
