import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ['element']
	static classes =  ['in', 'out']

	connect() {
		this.observer = new IntersectionObserver(entries => this.handleIntersection(entries))
		this.observer.observe(this.elementTarget)
	}

	disconnect() {
		this.observer.disconnect()
	}

	handleIntersection(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				if (this.hasInClass) {
					document.body.classList.add(...this.inClasses)
				}

				if (this.hasOutClass) {
					document.body.classList.remove(...this.outClasses)
				}
			} else {
				if (this.hasOutClass) {
					document.body.classList.add(...this.outClasses)
				}

				if (this.hasInClass) {
					document.body.classList.remove(...this.inClasses)
				}
			}
		})
	}
}
