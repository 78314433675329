import { Controller } from 'stimulus'

export default class extends Controller {
	connect() {
		this.inputs = Array.from(
			this.nearestForm.querySelectorAll('.optional input[required], .optional textarea[required]:not([data-controller="forms--ckeditor"]), .optional select[required]')
		)

		this.ckeditorTextareas = Array.from(
			// Let's try to avoid race conditions and get initialize and non-initialized CKEditor textareas
			this.nearestForm.querySelectorAll('.optional textarea[required][data-controller="forms--ckeditor"], .optional textarea[data-required][data-controller="forms--ckeditor"]')
		)
	}

	removeRequired() {
		this.inputs.forEach( i => i.removeAttribute('required'))

		this.ckeditorTextareas.forEach(textarea => {
			if (CKEDITOR.instances.hasOwnProperty(textarea.id)) {
				const editorInstance = CKEDITOR.instances[textarea.id];
				if (editorInstance) {
					editorInstance._.required = false;
					textarea.dataset.required = false;
				}
			}
		})
	}

	addRequired() {
		this.inputs.forEach( i => i.setAttribute('required', 'required'))

		this.ckeditorTextareas.forEach(textarea => {
			const editorInstance = CKEDITOR.instances[textarea.id];
			if (editorInstance) {
				editorInstance._.required = true;
				textarea.dataset.required = true;
			}
		})
	}

	get nearestForm() {
		return this.element.tagName === 'FORM' ? this.element : this.element.closest('form')
	}
}
