import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "decrease", "increase" ]

  initialize() {
    this.minimum = this.inputTarget.min !== "" ? parseInt(this.inputTarget.min) : null
    this.maximum = this.inputTarget.max !== "" ? parseInt(this.inputTarget.max) : null
  }

  connect() {
    if (!this.validInputType) return
    this.decreaseTarget.classList.remove("hidden")
    this.increaseTarget.classList.remove("hidden")
    this._checkState()
  }

  onButtonClick(event) {
    const target = event.currentTarget
    if (target.hasAttribute("disabled")) return

    target.dataset.operation === "-" ? this._doDecrease() : this._doIncrease()
    this._checkState()
    this._dispatchEvent()
  }

  onChange() {
    const input = this.inputTarget
    let currentValue = input.valueAsNumber

    if (isNaN(currentValue) || !input.checkValidity()) currentValue = this.minimum !== null ? this.minimum : 0
    input.value = currentValue
    this._checkState()
    this._dispatchEvent()
  }

  _dispatchEvent() {
    this.inputTarget.dispatchEvent(new Event('quantity:change'))
  }

  _checkState() {
    const currentValue = this.inputTarget.valueAsNumber
    const decreaseButton = this.decreaseTarget
    if (this.minimum !== null && this.minimum === currentValue) {
      decreaseButton.setAttribute("disabled", true)
    } else {
      decreaseButton.removeAttribute("disabled")
    }

    const increaseButton = this.increaseTarget
    if (this.maximum !== null && this.maximum === currentValue) {
      increaseButton.setAttribute("disabled", true)
    } else {
      increaseButton.removeAttribute("disabled")
    }
  }

  _doDecrease() {
    const input = this.inputTarget
    let newValue = this._valueForInput(input) - this.step

    if (this.minimum !== null) newValue = Math.max(this.minimum, newValue)
    input.value = newValue
  }

  _doIncrease() {
    const input = this.inputTarget
    let newValue = this._valueForInput(input) + this.step

    if (this.maximum !== null) newValue = Math.min(this.maximum, newValue)
    input.value = newValue
  }

  _valueForInput(input) {
    let newValue = input.valueAsNumber
    if (isNaN(newValue)) newValue = parseInt(input.min || 0)
    return newValue
  }

  get validInputType() {
    return this.inputTarget.type === "number"
  }

  get step() {
    let stepAmount = this.inputTarget.step
    if (stepAmount === 'any') stepAmount = 1

    return parseInt(stepAmount || 1)
  }
}
