import { Controller } from "stimulus"
import { loadStripe, initializeCardElement } from "../../utils/stripe"

export default class extends Controller {
	static targets = [ "card", "submit" ]

	initialize() {
		this.onCardChange = this.#onCardChange.bind(this)
		this.isValid = false
	}

	connect() {
		this.#checkFormElement()
		this.stripe = loadStripe()
		if (this.stripe) {
			this.field = initializeCardElement(this.stripe, this.cardTarget, { mode: "setup", currency: "eur" })
			this.field.addEventListener("change", this.onCardChange)
		}

		if (this.hasSubmitTarget) this.submitTarget.setAttribute("disabled", true)
	}

	disconnect() {
		if (this.field) this.field.removeEventListener("change", this.onCardChange)
	}

	onSubmit(event) {
		this.#checkFormElement()
		event.preventDefault()

		fetch(this.form.action, { method: this.form.method, headers: { 'X-CSRF-Token': this.csrfToken } })
			.then((response) => response.json())
			.then((data) => {
				this.stripe.confirmCardSetup(data.secret, { payment_method: { card: this.field } })
					.then((result) => {
						if (result.error) {
							if (this.hasSubmitTarget) this.submitTarget.setAttribute("disabled", true)
							if (result.error && alertify) alertify.error(result.error.message)
						} else {
							Turbo.visit(data.redirect)
						}
					})
			})
	}

	#onCardChange(event) {
		if (!this.hasSubmitTarget) return

		if (event.complete) {
			this.isValid = true
			this.submitTarget.removeAttribute("disabled")
		} else {
			this.submitTarget.setAttribute("disabled", true)
			if (event.error && alertify) alertify.error(event.error.message)
		}
	}

	#checkFormElement() {
		if (this.element.nodeName !== "FORM") throw new Error("Only <form> elements are allowed as controller element.")
	}

	get form() {
		return this.element
	}

	get csrfToken() {
		const meta = document.querySelector("meta[name=csrf-token]")
		return meta && meta.content
	}
}
