import { Controller } from "stimulus"

export default class extends Controller {
	/**
	 * for target value, we aren't using the regular `targets` option because some times we will not
	 * have the controller defined in a location where all the targets that we want to retrieve will
	 * be available, for instance when buttons in some component controls an external component, in this
	 * case because we also aren't targeting another Stimulus controller the outlets are of no use, so
	 * we are using a value as target and then we will all elements with `data-target` and work on them
	 */
	static values = { hideMessage:String, showMessage:String, hiddenOnLoad:Boolean, target:String }

	initialize() {
		this.hidden = this.hiddenOnLoadValue
	}

	connect() {
		this.#handle()
	}

	click() {
		this.hidden = !this.hidden
		this.#handle()
	}

	#handle() {
		this.element.innerText = this.hidden ? this.showMessageValue : this.hideMessageValue
		this.targets.forEach((element) => element.classList.toggle("hidden", this.hidden))
	}

	get targets() {
		if (this.targetValue === "") return []

		return document.querySelectorAll(`[data-target="${this.targetValue}"]`)
	}
}
