// REQUIRED ATTRIBUTES ON FORM:
//
// data-forms--track-changes-target="form"
// data-action="change->forms--track-changes#check keyup->forms--track-changes#check"
//
// OPTIONAL TARGETS AND ACTIONS:
//
// data--forms-track-changes-target="button"
// Enables/disables a save/submit button base on form state
//
// data--forms-track-changes-target="exclude"
// Prevents form element from being checked (useful for form elements that
// might change the form markup but don't actually add data)
//
// data-action="nested-fields:after-remove->forms--track-changes#check"
// Allows form to be re-checked when nested field blocks are removed

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "button", "exclude" ]

  initialize() {
    this._markExclusions()

    this.initialState = this._serialize()
    this.isSubmiting = false

    this.handleUnload = this._handleUnload.bind(this)
    this.handleTurbo = this._handleTurbo.bind(this)
    this.handleSubmit = this._handleSubmit.bind(this)

    this.check()
  }

  connect() {
    this._addHandlers()
  }

  disconnect() {
    this._removeHandlers()
  }

  check() {
    this.currentState = this._serialize()
    this.toggleButton()
  }

  toggleButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = !this.isDirty
      this.buttonTarget.classList.toggle("disabled", !this.isDirty);
    }
  }

  _addHandlers() {
    addEventListener("turbo:click", this.handleTurbo)
    addEventListener("beforeunload", this.handleUnload, {capture: true})

    this.formTarget.addEventListener('submit', this.handleSubmit)
  }

  _removeHandlers() {
    removeEventListener("turbo:click", this.handleTurbo)
    removeEventListener("beforeunload", this.handleUnload, {capture: true})

    this.formTarget.removeEventListener('submit', this.handleSubmit)
  }

  _markExclusions() {
    if (this.hasExcludeTarget) {
      // REVIEW this isn't great...
      this.excludeTargets.forEach(item => item.classList.add(this.exclusionClass))
    }
  }

  _handleSubmit() {
    this.isSubmiting = true
  }

  _handleTurbo(event) {
    if (!this.isSubmiting && this.isDirty) {
      // Disable Turbo to prevent user from leaving page
      event.preventDefault()
    }
  }

  _handleUnload(event) {
    if (!this.isSubmiting && this.isDirty) {
      event.preventDefault()
      // Some browsers require returnValue to be set
      event.returnValue = ''
    }
  }

  _serialize() {
    // Adapted from https://code.google.com/archive/p/form-serialize/
    if (!this.formTarget || this.formTarget.nodeName !== "FORM") {
      return;
    }
    var i, j, q = [];
    for (i = this.formTarget.elements.length - 1; i >= 0; i = i - 1) {
      if (this.formTarget.elements[i].name === "" ||
          this.formTarget.elements[i].name === "authenticity_token" ||
          (this.formTarget.elements[i].closest('.nested-fields') && this.formTarget.elements[i].closest('.nested-fields').offsetHeight === 0) || // REVIEW special case for nested associations
          this.formTarget.elements[i].classList.contains(this.exclusionClass)) {
        continue;
      }
      switch (this.formTarget.elements[i].nodeName) {
      case 'INPUT':
        switch (this.formTarget.elements[i].type) {
        case 'text':
        case 'hidden':
        case 'password':
        case 'email':
        case 'url':
          q.push(this.formTarget.elements[i].name + "=" + encodeURIComponent(this.formTarget.elements[i].value))
				  break;
        case 'checkbox':
        case 'radio':
          if (this.formTarget.elements[i].checked) {
            q.push(this.formTarget.elements[i].name + "=" + encodeURIComponent(this.formTarget.elements[i].value))
          }
          break;
        case 'file':
          q.push(this.formTarget.elements[i].name + "=" + encodeURIComponent(this.formTarget.elements[i].value))
          break;
        }
        break;
      case 'TEXTAREA':
        q.push(this.formTarget.elements[i].name + "=" + encodeURIComponent(this.formTarget.elements[i].value))
        break;
      case 'SELECT':
        switch (this.formTarget.elements[i].type) {
        case 'select-one':
          q.push(this.formTarget.elements[i].name + "=" + encodeURIComponent(this.formTarget.elements[i].value))
          break;
        case 'select-multiple':
          for (j = this.formTarget.elements[i].options.length - 1; j >= 0; j = j - 1) {
            if (this.formTarget.elements[i].options[j].selected) {
              q.push(this.formTarget.elements[i].name + "=" + encodeURIComponent(this.formTarget.elements[i].options[j].value))
            }
          }
          break;
        }
        break;
      }
    }
    return q.join("&")
  }

  get exclusionClass() {
    return 'track-changes-exclude'
  }

  get isDirty() {
    return this.initialState != this.currentState
  }
}
