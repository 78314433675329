import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "output", "input", "reset", "label" ]

	connect() {
		if (this.inputTarget.files.length > 0) this.readURL()
		this.inputTarget.addEventListener('change', () => this.checkField() );
	}

	disconnect() {
		this.inputTarget.removeEventListener('change', () => this.checkField() );
	}

	readURL() {
		const files = this.inputTarget.files
		if (files.length > 0) {
			const reader = new FileReader()
			reader.onload = () => {
				let image = this.outputTarget.querySelector('img')
				if (!image) {
					image = document.createElement('img')
					image.setAttribute('class', 'w-full h-full object-cover')
					this.outputTarget.appendChild(image)
					this.outputTarget.classList.remove('hidden')
				}
				image.src = reader.result
			}
			reader.readAsDataURL(files[0])

			if (this.hasLabelTarget) {
				this.labelTarget.classList.add('hidden')
			}

			if (this.hasResetTarget) {
				this.resetTarget.classList.remove('hidden')
			}
		}
	}

	checkField() {
		if (this.inputTarget.value === '') {
			this.clearField()
		}
	}

	clearField() {
		this.inputTarget.value = ''
		this.outputTarget.innerHTML = ''

		this.outputTarget.classList.add('hidden')

		if (this.hasLabelTarget) {
			this.labelTarget.classList.remove('hidden')
		}

		if (this.hasResetTarget) {
			this.resetTarget.classList.add('hidden')
		}
	}
}
