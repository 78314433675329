import { Controller } from "stimulus"

export default class extends Controller {
	static values = { inputHide: String, inputShow: String, fieldsHide: Array, fieldsShow: Array }

	// REVIEW: this needs to be reworked. All the conditional inputs should be under
	// the controller and the logic should be set on each input - not on the select/toggler
	// itself, as it is right now.

	connect() {
		if(this.element.value == '') {
			this._hideAllFields()
		} else {
			this._hideFields(this.element.value)
			this._showFields(this.element.value)
		}
	}

	hideFields(e) {
		this._hideFields(e.target.value)
	}

	showFields(e) {
		this._showFields(e.target.value)
	}

	_hideAllFields() {
		this.fieldsHideValue.concat(this.fieldsShowValue).forEach((fieldId) => {
			const el = document.getElementById(fieldId)
			if (!el) return

			const input = el.querySelector('select, input')

			el.classList.add('hidden')

			if (input) {
				input.setAttribute('disabled', '')
			}
		})
	}

  _hideFields(value) {
		this.fieldsHideValue.forEach((fieldId) => {
			const el = document.getElementById(fieldId)
			if (!el) return

			const input = el.querySelector('select, input')

			if (value == this.inputHideValue ) {
				el.classList.add('hidden')

				if (input) {
					input.setAttribute('disabled', '')
				}
			} else {
				el.classList.remove('hidden')

				if (input) {
					input.removeAttribute('disabled')
				}
			}
		})
  }

	_showFields(value) {
		this.fieldsShowValue.forEach((fieldId) => {
			const el = document.getElementById(fieldId)
			if (!el) return

			const input = el.querySelector('select, input')

			if (value == this.inputShowValue ) {
				el.classList.remove('hidden')

				if (input) {
					input.removeAttribute('disabled')
				}
			} else {
				el.classList.add('hidden')

				if (input) {
					input.setAttribute('disabled', '')
				}
			}
		})
	}
}
