import { Controller } from "stimulus"
import ScriptLoader from "../utils/scriptloader"

export default class extends Controller {
	// REVIEW: there's some overlap with the `carousel`, could probably be merged

	static targets = [ 'nav', 'controls' ]
	static values = { options: Object, xxxlarge: Number, xlarge: Number, medium: Number, small: Number }

	initialize() {
		this.instance = null
		this.optionsValue = this.hasOptionsValue ? this.optionsValue : {}
		this.totalSlides = this.element.querySelectorAll('.glide__slide').length
		this.destroy = this._destroy.bind(this)
	}

	connect() {
		ScriptLoader.loadScript('glide', () => {
			const glideOptions = {
				...this.defaultOptions,
				...this.optionsValue,
			}

			this.instance = new Glide(this.element, glideOptions)
			this.bullets = [] // Cache bullets to avoid repeated DOM querying

			// Attach the event handlers to the instance
			this.instance.on(['mount.before'], this.addBullets.bind(this))
			this.instance.on(['resize', 'build.after'], this.updateBulletVisibility.bind(this))

			this.instance.mount().update()

			if (this.totalSlides > this.instance.settings.perView) {
				this.enable()
			}

			// Event listener for Turbo cache
			document.addEventListener('turbo:before-cache', this.destroy.bind(this))
		})
	}

	enable() {
		this.navTarget.classList.add('opacity-100', 'pointer-events-auto')
		this.controlsTarget.classList.add('opacity-100', 'pointer-events-auto')
	}

	addBullets() {
		const bulletCount = this.totalSlides

		// Create bullets only once
		if (this.bullets.length === 0) {
			for (let index = 0; index < bulletCount; index++) {
				const button = document.createElement('button')
				button.classList.add('glide__bullet', 'h-2', 'rounded-full', 'w-2', 'thu-bg-neutral', 'transition-all', 'duration-200', 'hover:thu-bg-primary')
				button.setAttribute('data-glide-dir', '=' + index)
				this.navTarget.appendChild(button)
				this.bullets.push(button) // Cache bullet element
			}
		}
	}

	updateBulletVisibility() {
    const perView = this.instance.settings.perView
    const total = this.totalSlides
		const maxVisible = total - perView

    const bulletIndices = [];

    // Calculate indices at intervals of `perView`
    for (let i = 0; i <= total - perView; i += perView) {
        bulletIndices.push(i);
    }

    // Ensure last bullet covers the final slides, if needed
    const lastBulletIndex = total - perView;
    if (lastBulletIndex > bulletIndices[bulletIndices.length - 1]) {
        bulletIndices.push(lastBulletIndex);
    }

    // Show or hide bullets based on calculated indices
    this.bullets.forEach((bullet, i) => {
        if (bulletIndices.includes(i)) {
            bullet.classList.remove('hidden');
        } else {
            bullet.classList.add('hidden');
        }
    });

		// Prevents the empty last stop when resized for a larger breakpoint with more items
		if (this.instance.index > maxVisible) {
			this.instance.go('=' + maxVisible)
		}
	}

	disconnect() {
		document.removeEventListener('turbo:before-cache', this.destroy)
	}

	#clearControls() {
		this.navTarget.innerHTML = ''
	}

	_destroy() {
		if(this.instance) {
			this.instance.destroy()
			this.instance = null
		}

		if (this.hasNavTarget) this.#clearControls()
	}

	peekValue(gap) {
		const totalColumns = 12
		const columnsToOffset = 1
		const margin = 24

		const columnWidth = (this.element.clientWidth  - (totalColumns - 1) * gap) / totalColumns
		const offset = (columnWidth * columnsToOffset) + gap + margin

		return offset
	}

	get defaultOptions() {
		return {
			type: 'slider',
			perView: this.xxxlargeValue,
			keyboard: false,
			rewind: false,
			peek: { before: this.peekValue(40), after: this.peekValue(40) },
			gap: 0,
			bound: true,
			breakpoints: {
				639: {
					perView: this.smallValue,
					peek: { before: 16, after: 40 }
				},
				767: {
					perView: this.smallValue,
					peek: { before: 48, after: 48 }
				},
				1023: {
					perView: this.mediumValue,
					peek: { before: 48, after: 48 }
				},
				1279: {
					perView: this.mediumValue
					// peek: { before: 48, after: 48 }
				},
				1791: {
					perView: this.xlargeValue,
					peek: { before: this.peekValue(24), after: this.peekValue(24) }
				}
			},
			classes: {
				activeNav: 'thu-bg-secondary',
				disabledArrow: 'pointer-events-none opacity-50'
			}
		}
	}
}
