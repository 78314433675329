import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "card", "modal" ]

  initialize() {
    this.onCardClick = this._onCardClick.bind(this)
    this.onVideoChange = this._onVideoChange.bind(this)

    this.onModalOpen = this._onModalOpen.bind(this)
    this.onModalClose = this._onModalClose.bind(this)

    this.startPlayer = this._startPlayer.bind(this)
    this.onPlayerTrigger = this._onPlayerTrigger.bind(this)

    this.player = {}
  }

  connect() {
    document.querySelectorAll(".video-gallery__modal-card").forEach((item) => {
      item.addEventListener('click', (e) => {
        this._onVideoChange(e.currentTarget.dataset.url) 
      });
    })
  }
  
  _onModalOpen(event) {
    this.modalTarget.classList.toggle('hidden');
    document.querySelector("body").classList.add("overflow-hidden");

    document.querySelectorAll(".video-gallery__modal-card").forEach((item) => {
      item.addEventListener('click', (e) => {
        this._onVideoChange(e.currentTarget.dataset.url) 
      });
    })

    this.onCardClick(document.querySelector(".player-modal").dataset.url);
  }

  _onModalClose() {
    this.modalTarget.classList.toggle('hidden');
    document.querySelector("body").classList.remove("overflow-hidden");
    
    if (Object.keys(this.player).length !== 0) {
      this.player.stopVideo();
      return;
    }
  }

  _onPlayerTrigger() {
    this.onCardClick(document.querySelector(".player-modal").dataset.url);
  }

  _onCardClick(v) {

    let hasYt = false;
    document.querySelectorAll("script").forEach( (s) => {
      if (s.src == "https://www.youtube.com/player_api") {
        hasYt = true
      }
    })

    if (hasYt) {
      this.startPlayer(v)
      return
    } else {
      let tag = document.createElement('script');
      tag.src = "https://www.youtube.com/player_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      if (Object.keys(this.player).length !== 0) {
        this.player.stopVideo();
        this.player.loadVideoById({videoId: v})
        return;
      } else (
        window.onYouTubePlayerAPIReady = () => {
          this.startPlayer(v)
        }
      )
 
    }
  }

  _startPlayer(v) {
    this.player = new YT.Player('videoplayer', {
      height: '100%',
      width: '100%',
      videoId: v,
      events: {
        'onReady': this.onPlayerReady
      }
    });
  }

  _onVideoChange(v) {

    document.querySelector(".video-gallery__modal-card.active").classList.remove("active");
    
    document.querySelectorAll(".video-gallery__modal-card").forEach((item) => {
      if(item.dataset.url == v) {
        item.classList.add("active")
      }
    })

    if (Object.keys(this.player).length !== 0) {
      this.player.stopVideo();
      this.player.loadVideoById({videoId: v})
      return;
    }
  }

  onPlayerReady(event) {
    event.target.playVideo();
  }

  disconnect() {

  }

}