import { Controller } from "stimulus"
import { loadStripe, sessionSetup } from "../../utils/stripe"
import Rails from "@rails/ujs"

export default class extends Controller {
	static targets = [ "payment", "submit", "input" ]
	static values = { setupUrl: String, returnUrl: String, currency: String, billingDetails: Object }

	connect() {
		if (this.element.tagName !== "FORM") {
			console.error("This controller must be attached to a form element")
			return
		}

		this.stripe = loadStripe()
		if (!this.stripe) return

		this.#setup()
	}

	async onSubmit(event) {
		event.preventDefault()
		event.stopImmediatePropagation()

		if (this.shouldValidate()) {
			const { error: submitError } = await this.elements.submit()
			if (submitError) {
				// NOTE: far from perfect solution, but for now we will leave it that way, basically we will
				// show an alert with the first error message, but the fields will all be highlighted signaling
				// the error for each field, so eventually we need to check if it makes sense to have a more generic
				// error message instead of displaying the elements error
				if (alertify) alertify.error(submitError.message)
				return
			}
		}

		// we disable the submit element and we will start the Stripe setup confirmation
		Rails.disableElement(this.submitTarget)

		if (!this.shouldValidate()) {
			this.element.submit()
			return
		}

		// if the payment method target already has a value it means that the user wants to use
		// an already saved card, so we just need to submit the form and move on
		if (this.inputTarget.value) {
			this.element.submit()
		} else {
			this.stripe.confirmSetup({
				elements: this.elements,
				redirect: "if_required",
				confirmParams: {
					payment_method_data: { billing_details: this.billingDetailsValue },
					return_url: this.returnUrlValue
				}
			})
			.then((result) => {
				if (result.error) {
					Rails.enableElement(this.submitTarget)
					if (alertify) alertify.error(result.error.message)
				} else {
					this.inputTarget.value = result.setupIntent.payment_method
					this.element.submit()
				}
			})
		}
	}

	async #setup() {
		if (!this.shouldValidate()) return

		({ elements: this.elements, paymentElement: this.paymentElement } =
			await sessionSetup(this.setupUrlValue, this.stripe, this.paymentTarget, { currency: this.currencyValue }))

		this.paymentElement.on("change", (event) => {
			this.inputTarget.value = event.value.payment_method ? event.value.payment_method.id : null
		})
	}

	shouldValidate() {
		return window.App.environment !== "test"
	}
}
