import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
	static component = "passes"

	onClick(event) {
		console.log("iOS user clicked on pass")
		console.log("event will be prevented")

		event.preventDefault()

		const passUrl = event.currentTarget.href
		console.log(`URL ${passUrl} will be sent to bridge on iOS`)

		this.send("connect", { passUrl })
	}
}
