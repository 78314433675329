import { Controller } from "stimulus"
import ScriptLoader from "../../utils/scriptloader"

export default class extends Controller {
	static targets = [ "input", "template" ]
	static values = {
		wordcountPath: String,
		notificationPath: String,
		toolbar: String
	}

	initialize() {
		this.showWordcount = false
		this.maxCharacters = this.inputTarget.maxLength || 0
		this.destroy = this._destroy.bind(this)
	}

	connect() {
		if (this.inputTarget.nodeName !== "TEXTAREA") {
			console.error("CKEDITOR can only be loaded in a textarea element")
			return
		}

		ScriptLoader.loadScript("ckeditor", () => {
			if (this.hasWordcountPathValue && this.hasNotificationPathValue && this.maxCharacters > 0) {
				CKEDITOR.plugins.addExternal(
					"wordcount",
					this.wordcountPathValue
				)

				CKEDITOR.plugins.addExternal(
					"notification",
					this.notificationPathValue
				)

				this.showWordcount = true
			}

			CKEDITOR.on('dialogDefinition', function(e) {
				if (e.data.name == 'image') {
					const dialog = e.data.definition
					const oldOnShow = dialog.onShow

					dialog.removeContents( 'Link' )
					dialog.removeContents( 'advanced' )

					dialog.onShow = function() {
						oldOnShow.apply(this, arguments)

						if(this.imageEditMode === false) { this.selectPage('Upload'); }
					}
				}
			})

			CKEDITOR.addCss( '.cke_editable { font-size: 16px }' )
			this.required = this.inputTarget.required
			this.instance = CKEDITOR.replace(this.inputTarget, this.#optionsForCKEDITOR())

			if (this.required) {
				this.inputTarget.dataset.required = true

				this.instance.on('required', (event) => {
					event.cancel()

					const inlineError = document.createElement('p')
					inlineError.classList.add('inline-errors')
					inlineError.textContent = "can’t be blank"

					const formField = this.element.closest('.thu-form__field')
					if (!formField.querySelector('.inline-errors')) {
						formField.classList.add('error')
						formField.append(inlineError)
					}
					formField.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })

					// Re-enable all submit buttons
					this.enableElements()
				})
			}

			document.addEventListener('turbo:before-cache', this.destroy )
		})
	}

	disconnect() {
		document.removeEventListener('turbo:before-cache', this.destroy )
	}

	insertTemplate(event) {
		const input = event.target
		const template = this.templateTargets.find((template) => template.id === input.dataset.template)
		if (template) this.instance.setData(template.innerHTML)
	}

	enableElements() {
		setTimeout(() => {
			this.nearestForm.querySelectorAll('input[type="submit"]').forEach(button => {
				button.removeAttribute('disabled')
			})
		}, 200)
	}

	#optionsForCKEDITOR() {
		const options = {}
		let toolbar = [{ "name": "basicstyles", "groups": ["list", "basicstyles"], "items": [ "NumberedList", "BulletedList", "Bold", "Italic", "Underline", "Strike", "Blockquote"]}, { "name": "links", "items": [ "Link", "Unlink" ] }]

		if  (this.hasToolbarValue) {
			switch(this.toolbarValue) {
				case 'emphasis_only':
					toolbar = [{ "name": "basicstyles", "groups": ["basicstyles"], "items": [ "Bold", "Italic", "Underline", "Strike"]}]
					break;
				case 'basic_styles':
					toolbar = [{ "name": "basicstyles", "groups": ["basicstyles"], "items": [ "Bold", "Italic", "Underline", "Strike", "Blockquote"]}, { "name": "links", "items": [ "Link", "Unlink" ] }]

					break;
				case 'user_content':
					toolbar = [{ name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] }, { name: 'links', items: [ 'Link', 'Unlink' ] }, { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-'] }, { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Blockquote', '-', 'RemoveFormat', '-' ] }]

					break;
				case 'user_content_with_image':
					toolbar = [{ name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] }, { name: 'links', items: [ 'Link', 'Unlink' ] }, { name: 'insert', items: [ 'Image', 'Embed' ] }, { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-'] }, { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Blockquote', '-', 'RemoveFormat', '-' ] }]

					// Browser uploads should only be defined when there are attachment options on the toolbar, otherwise the file will be uploaded, a message will be shown but no file will show up on the ckeditor
					options.filebrowserFlashBrowseUrl = '/ckeditor/attachment_files'
					options.filebrowserFlashUploadUrl = '/ckeditor/attachment_files'
					options.filebrowserUploadUrl = '/ckeditor/attachment_files'
					options.filebrowserImageUploadUrl = '/ckeditor/pictures?command=QuickUpload&type=Images'
					break;
				default:
					toolbar = toolbar
			}
		}

		options.versionCheck = false

		options.toolbar = toolbar

		options.height = 240
		options.resize_enabled = false
		options.removePlugins = "elementspath"

		options.filebrowserBrowseUrl = ''
    options.filebrowserImageBrowseLinkUrl = ''
    options.filebrowserImageBrowseUrl = ''
    options.filebrowserFlashBrowseUrl = '/ckeditor/attachment_files'
    options.filebrowserFlashUploadUrl = '/ckeditor/attachment_files'
    options.filebrowserImageUploadUrl = '/ckeditor/pictures?command=QuickUpload&type=Images'
    options.filebrowserUploadUrl = '/ckeditor/attachment_files'
    options.extraPlugins = "embedbase,embed,autolink,autoembed"
    options.embed_provider = '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}&consent=0'

		if (this.showWordcount) {
			options.extraPlugins = "wordcount,notification"
			options.wordcount = {
				showRemaining: false,
				showParagraphs: false,
				showWordCount: false,
				showCharCount: true,
				maxCharCount: this.maxCharacters
			}
		}

		return options
	}

	_scrollParent(node) {
		if (node == null) {
			return null
		}

		if (node.scrollHeight > node.clientHeight) {
			return node
		} else {
			return this._scrollParent(node.parentNode)
		}
	}

	get nearestForm() {
		return this.element.tagName === "FORM" ? this.element : this.element.closest("form")

	}

	_destroy() {
		if(this.instance) {
			this.instance.destroy()
			this.instance = null
		}
	}
}
