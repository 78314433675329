export function isSameDay(date, compare) {
	return (
		date.getFullYear() === compare.getFullYear() &&
		date.getMonth() === compare.getMonth() &&
		date.getDate() === compare.getDate()
	)
}

export function isToday(date) {
	return isSameDay(date, new Date())
}

export function isYesterday(date) {
	const yesterday = new Date()
	yesterday.setDate(yesterday.getDate() - 1)
	return isSameDay(date, yesterday)
}

export function isLastWeek(date) {
	return diffInDays(date, new Date()) <= 7
}

export function isSameMonth(date) {
	return diffInMonths(date, new Date()) === 0
}

export function isSameYear(date) {
	return diffInYears(date, new Date()) === 0
}

export function diffInDays(d1, d2) {
	const t2 = d2.getTime()
	const t1 = d1.getTime()
	return Math.floor((t2 - t1) / (24 * 3600 * 1000))
}

export function diffInWeeks(d1, d2) {
	const t2 = d2.getTime()
	const t1 = d1.getTime()
	return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7))
}

export function diffInMonths(d1, d2) {
	const d1Y = d1.getFullYear()
	const d2Y = d2.getFullYear()
	const d1M = d1.getMonth()
	const d2M = d2.getMonth()

	return (d2M + 12 * d2Y) - (d1M + 12 * d1Y)
}

export function diffInYears(d1, d2) {
	return d2.getFullYear() - d1.getFullYear()
}

export function diffInMinutes(d1, d2) {
	return Math.abs(Math.round((d2.getTime() - d1.getTime()) / 1000 / 60))
}
