import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "input" ]
	static values = { normalizeAccents: Boolean }

	connect() {
		this.#normalize()
	}

	onInput() {
		this.#normalize()
	}

	#normalize() {
		let value = this.inputTarget.value
		if (value === "") return

		value = value.toLowerCase().replace(/\s/g , "-")
		if (this.normalizeAccentsValue) value = this.#normalizeAccents(value)

		this.inputTarget.value = value
	}

	#normalizeAccents(value) {
		const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç"
		const to = "aaaaaeeeeeiiiiooooouuuunc"

		for (let x = 0; x < from.length; x++) {
			value = value.replace(new RegExp(from.charAt(x), "g"), to.charAt(x))
		}

		return value
	}
}
