import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "legend" ]
	static values = { open: String }

	initialize() {
		this.onToggle = this.#onToggle.bind(this)
		this.defaultLabel = null
	}

	connect() {
		if (this.element.tagName !== "DETAILS") return

		// basically we only do anything at all if we have a legend target defined and the value for
		// open value is defined ... otherwise it's pointless to do this
		if (this.hasLegendTarget && this.hasOpenValue) {
			this.defaultLabel = this.legendTarget.innerText
			this.element.addEventListener("toggle", this.onToggle)
			// we call the `#onToggle` right away because the `<details>` can be defined to be on
			// open state, so need to update the legend accordingly
			this.#onToggle()
		}
	}

	toggle() {
		if (this.element.hasAttribute('open')) {
			this.element.removeAttribute('open');
		} else {
			this.element.setAttribute('open', '');
		}
	}

	disconnect() {
		if (this.hasLegendTarget) this.element.removeEventListener("toggle", this.onToggle)
	}

	#onToggle() {
		this.legendTarget.innerText = this.element.open ? this.openValue : this.defaultLabel
	}
}
