import { Controller } from "stimulus";

export default class extends Controller {
	static values = { themes: Object }

	disconnect() {
		document.adoptedStyleSheets.pop()
	}

	handleChange(e) {
		var theme_id = e.target.options[e.target.selectedIndex].value
		var sheet = new CSSStyleSheet();

		sheet.replaceSync(this.themesValue[theme_id]);
		document.adoptedStyleSheets = [sheet];
	}
}
