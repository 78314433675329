export function sourceForVideoProvider(provider, embedID) {
	let videoURL
	switch(provider) {
		case 'vimeo':
			videoURL = `https://player.vimeo.com/video/${embedID}?autoplay=1&title=0&byline=0&portrait=0`
			break;

		case 'youtube':
			videoURL = `https://www.youtube-nocookie.com/embed/${embedID}?autoplay=1&modestbranding=1&rel=0`
			break;

		case 'bilibili':
			videoURL = `https://player.bilibili.com/player.html?bvid=${embedID}&autoplay=1`
			break;
	}

	return videoURL
}

export function leadingZeroFormat(value, force = true) {
	if (!force) return value

	return value < 10 ? String(value).padStart(2, "0") : value
}

export function timeRemaining(end) {
	const remaining = Date.parse(end) - Date.parse(new Date())
	let seconds = Math.floor(remaining / 1000)
	let minutes = Math.floor(seconds / 60)
	let hours = Math.floor(minutes / 60)
	let days = Math.floor(hours / 24)

	seconds = seconds % 60
	minutes = minutes % 60
	hours = hours % 24

	return { days, hours, minutes, seconds }
}

export function getUserPreferences() {
	// user preferences should be set in a body tag, we will check there, if we don't have anything
	// we will just return an empty object, if some JS depends on that preferences but they don't
	// exist in a given context ... things will break
	const preferences = document.body.dataset.userPreferences
	if (!preferences) return {}

	return JSON.parse(preferences)
}
