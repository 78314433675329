import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {

  static component = "theme"

  connect() {
    super.connect()
    this.#notifyBridgeOfConnect()
  }

  #notifyBridgeOfConnect() {
    const element = this.bridgeElement
    const backgroundColor = window.getComputedStyle(this.element).backgroundColor

    this.send("connect", {title: element.title, backgroundColor})
  }
}