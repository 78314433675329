import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "container", "template" ]
	static values = { maximumResources: Number, wrapperClass: { type: String, default: "nested-fields" } }

	connect() {
		const destroyedElements = this.element.querySelectorAll(".remove-fields.destroyed")
		destroyedElements.forEach((element) => {
			element.closest(`.${this.wrapperClassValue}`).classList.add("hidden")
		})
		this.#checkMaximumResources()
	}

	addAssociation(event) {
		event.preventDefault()

		const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
		const node = this.containerTarget.appendChild(document.createRange().createContextualFragment(content).firstElementChild)
		const addEvent = new CustomEvent("nested-fields:after-insert", { bubbles: true, detail: { element: node, originalEvent: event } })
		this.element.dispatchEvent(addEvent)
		this.#checkMaximumResources()
	}

	removeAssociation(event) {
		event.preventDefault()

		const button = event.currentTarget
		const wrapper = button.closest(`.${this.wrapperClassValue}`)
		const newRecord = JSON.parse(button.dataset.newRecord)

		if (newRecord) {
			wrapper.remove()
		} else {
			wrapper.querySelector('input[name*="_destroy"]').value = 1
			wrapper.classList.add("hidden")
		}

		const removalEvent = new CustomEvent("nested-fields:after-remove", { bubbles: true, detail: { element: wrapper, originalEvent: event } })
		this.element.dispatchEvent(removalEvent)
		this.#checkMaximumResources()
	}

	#checkMaximumResources() {
		if (!this.hasMaximumResources) return

		const nestedFields = Array.from(this.element.querySelectorAll(`.${this.wrapperClassValue}`)).filter((field) => !field.classList.contains("hidden"))
		this.addMoreButton.classList.toggle("hidden", nestedFields.length >= this.maximumResourcesValue)
	}

	get addMoreButton() {
		return this.element.querySelector(".add-fields")
	}

	get hasMaximumResources() {
		return this.maximumResourcesValue > 0
	}
}
