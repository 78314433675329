import { Controller } from "stimulus"
import TweenMax from "gsap"

export default class extends Controller {
  static targets = [ "outter" ]

  initialize() {

    this.onMouseMove = this._onMouseMove.bind(this)
    this.onMouseHover = this._onMouseHover.bind(this)
    this.onMouseHoverOut = this._onMouseHoverOut.bind(this)

    // Listeners
    document.body.addEventListener('mousemove', this.onMouseMove);
    const hoverables = document.querySelectorAll('a, .clickable');

    for (let i = 0; i < hoverables.length; i++) {
      hoverables[i].addEventListener('mouseenter', this.onMouseHover);
      hoverables[i].addEventListener('mouseleave', this.onMouseHoverOut);
    }

  }

  _onMouseMove(e) {
    TweenMax.to(this.outterTarget,  {
      x: e.pageX - 16,
      y: e.pageY - 16,
      duration: .5
    })
  }

  _onMouseHover() {
    TweenMax.to(this.outterTarget, {
      duration: .3,
      scale: 2.5
    })
  }

  _onMouseHoverOut() {
    TweenMax.to(this.outterTarget, {
      duration: .3,
      scale: 1
    })
  }

}
