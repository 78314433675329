import { Controller } from "stimulus"
import * as Utils from "../../utils/common"

export default class extends Controller {
	static targets = [ "audio", "frame", "tab", "button", "time", "nav", "now", "upcoming" ]
	static classes = [ "tabOpen", "timeWarningText" ]
	static values = { meetingUrl:String, leaveUrl:String, userName:String, theme:Object }

	initialize() {
		this.meetingStartInterval = null
		this.meetingEndInterval = null
		this.daily = null
		this.inMeeting = false
	}

	connect() {
		if (this.meetingUrlValue === "") {
			console.error("Ensure that a meeting URL is being defined.")
			return
		}

		this.#join()
	}

	tabOpen(event) {
		const button = event.target
		const isPressed = button.getAttribute("aria-pressed") === "true"
		const tab = this.tabTargets.find((tab) => tab.id === button.dataset.tab)
		if (!tab) return

		// OK, before we go and show the tab, we need to check if there's any other than, other
		// than this that is already open, in that case we need to hide the other and only then
		// show this one
		const openTab = this.tabTargets.find((item) => item.classList.contains(this.tabOpenClass) && item.id !== tab.id)
		if (openTab) this.#hideTab(openTab)

		tab.classList.toggle(this.tabOpenClass, !isPressed)
		button.setAttribute("aria-pressed", !isPressed)
	}

	tabClose(event) {
		const button = event.target
		const tab = button.closest(".side-slider")
		if (tab) this.#hideTab(tab)
	}

	join() {
		this.#join()

		// basically we are using a `setTimeout` in order to not show the error message that
		// `Daily` show, so we start the join process and then only 500ms later we swap the visibility
		// of the elements
		setTimeout(() => {
			this.frameTarget.classList.remove("unavailable")
			if (this.hasNowTarget) this.nowTarget.classList.add("unavailable")
		}, 500)
	}

	#setupDaily() {
		// OK, we will use `wrap` method in our frame target, after that we will call the
		// `join` method in order to user join the call
		this.daily = window.DailyIframe.wrap(this.frameTarget, { url: this.meetingUrlValue, userName: this.userNameValue, showLeaveButton: true })
		// we will set a custom theme if we have one defined
		if (Object.keys(this.themeValue).length > 0) this.daily.setTheme(this.themeValue)

		this.daily.on("error", this.#handleErrors.bind(this))
		// on `joining-meeting` we want to ensure that the Daily iFrame is available and the now card
		// is hidden, otherwise things can get crazy
		this.daily.on("joining-meeting", () => {
			this.frameTarget.classList.remove("unavailable")
			if (this.hasNowTarget) this.nowTarget.classList.add("unavailable")
			if (this.meetingStartInterval) clearInterval(this.meetingStartInterval)
		})
		// OK, so the user have already joined the meeting, we will start the clock to the end of the
		// meeting call
		this.daily.on("joined-meeting", () => {
			this.#startMeetingEndCountdown()
			this.inMeeting = true
		})
		// OK, if we have a leave URL value defined when leaving a Daily meeting we will redirect to
		// that page, otherwise we just don't do anything
		this.daily.on("left-meeting", () => {
			if (this.leaveUrlValue !== "" && this.inMeeting) window.location = this.leaveUrlValue
		})
	}

	#join() {
		clearInterval(this.meetingStartInterval)
		if (!this.daily) this.#setupDaily()
		this.daily.join()
	}

	#playWarning() {
		if (this.hasAudioTarget) {
			this.audioTarget.currentTime = 0
			this.audioTarget.volume = 0.3
			this.audioTarget.play()
		}
	}

	#hideTab(tab) {
		tab.classList.remove(this.tabOpenClass)
		// because we are closing the tab through a close event, we also need to find the
		// button that triggered the tab open and change it's `aria-pressed` to `false`
		const trigger = this.buttonTargets.find((item) => item.dataset.tab === tab.id)
		if (trigger) trigger.setAttribute("aria-pressed", false)
	}

	#formatTimeRemaining(remaining) {
		// TLDR; we will hide time if it takes more than 1 day
		//
		// OK, if for some reason we receive a value that have more than 1 day (actually value will
		// be `0`) we will just opt to now show the current time for the thing, we shouldn't have
		// meetings that take days
		if (remaining.days > 0) {
			this.timeTarget.classList.add("invisible")
		} else {
			const parts = []
			if (remaining.hours > 0) parts.push(Utils.leadingZeroFormat(remaining.hours))
			parts.push(Utils.leadingZeroFormat(remaining.minutes))
			parts.push(Utils.leadingZeroFormat(remaining.seconds))
			this.meetingEndElement.innerText = parts.join(":")
		}
	}

	#startMeetingEndCountdown() {
		const datetime = this.meetingEndElement.dateTime

		this.meetingEndInterval = setInterval(() => {
			// OK, we need to check if we have reached the limit of time, basically we will sum
			// all the values from the remaining object
			const parts = Utils.timeRemaining(datetime)
			// basically if the sum of all parts if below zero, it means that we have reached the
			// time to the end of the meeting, so we just clear the interval and hide the time element
			const remaining = Object.values(parts).reduce((previous, value) => previous + value, 0)
			if (remaining > 0 && parts.days === 0) {
				this.#formatTimeRemaining(parts)
				this.#checkTimeWarning(parts)
				this.timeTarget.classList.remove("invisible")
			} else {
				// so we remove the interval and hide the time element, additionally we are triggering
				// a custom event `time:elapsed` if any other element wants to catch it
				clearInterval(this.meetingEndInterval)
				this.timeTarget.classList.add("invisible")
				this.meetingEndElement.dispatchEvent(new CustomEvent("time:elapsed", { bubbles: true }))
			}
		}, 1000)
	}

	#checkTimeWarning(parts) {
		// if we still have hours (or days) to go, we just don't do anything
		if (parts.hours > 0 || parts.days > 0) return
		// OK, to simply this, we will only do this check when seconds value is 0 (zero), because
		// there's no need to do any check after that
		if (parts.seconds !== 0) return
		// OK, we also don't need to show the warning / blinking if we didn't reached out to the
		// 5 minutes threshold
		if (parts.minutes > 5) return

		// OK, first we set the class for time warning color
		if (this.hasTimeWarningTextClass) this.timeTarget.classList.add(this.timeWarningTextClass)

		// we will start to display the warning when we are reaching the mark of 5 minutes,
		// from there down ... we will then increase the iteration of the animation to play more
		// time
		const iteration = (Math.abs(parts.minutes - 5) + 1) * 10
		this.navTarget.style.setProperty("--blinking-iteration-count", iteration)
		this.navTarget.addEventListener("animationend", () => this.navTarget.classList.remove("blinking"), { once: true })
		this.navTarget.classList.add("blinking")

		// we will play a warning sound (if any defined) if we are in the last minute of the call
		if (parts.minutes === 1) this.#playWarning()
	}

	#handleErrors(data) {
		if (data.error) {
			// right now we are handling `nbf-room` and `exp-room`, the first will happen when the
			// user arrives at the page before the time allowed to join the meeting, the other error will
			// happen if the user arrives in an room which already ended
			if (data.error.type === "nbf-room") {
				this.#setupAutoJoin()
			} else if (data.error.type === "exp-room") {
				// we will just redirect based on leaveUrl value
				if (this.leaveUrlValue !== "") window.location = this.leaveUrlValue
			}
		} else {
			// OK, here we will need to check what we want to do, because if the `error` does not come
			// we are dealing with an undefined error like a 404 on a Room in Daily
		}
	}

	#setupAutoJoin() {
		if (this.hasNowTarget) this.nowTarget.classList.remove("unavailable")
		this.frameTarget.classList.add("unavailable")

		// we start by clearing any possible already defined interval
		if (this.meetingStartInterval) clearInterval(this.meetingStartInterval)

		const datetime = this.meetingStartElement.dateTime
		// basically we will check if we are approaching the meeting start time, and basically if
		// we are at 3 minutes of the meeting start we will try to join
		this.meetingStartInterval = setInterval(() => {
			const parts = Utils.timeRemaining(datetime)
			if (parts.days === 0 && parts.hours === 0 && parts.minutes <= 3) {
				this.#join()
				// we will be clearing the interval right away, because we don't want to arrive in a
				// loop of joining attempts, if for some reason is still not available the room, the
				// `setupAutoJoin` will be called again
				clearInterval(this.meetingStartInterval)
			}
		}, 60000)
	}

	get meetingStartElement() {
		return this.element.querySelector('[data-type="meetingStart"]')
	}

	get meetingEndElement() {
		return this.element.querySelector('[data-type="meetingEnd"]')
	}
}
