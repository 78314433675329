import { Controller } from "stimulus"
import jsQR from "jsqr"

export default class extends Controller {
	static targets = [ "canvas", "dialog"]
	static values = { url:String }

	initialize() {
		this.facingMode = "environment"
		this.video = document.createElement("video")
	}

	connect() {
		this.videoStream = null
		this.scanning = false
	}

	disconnect() {
		this._stopScanning()
	}

	open() {
		this.dialogTarget.showModal()
		this._startScanning()
	}

	close() {
		this.dialogTarget.close()
		this._stopScanning()
	}

	swapFacingMode() {
		this.facingMode = this.facingMode === 'environment' ? 'user' : 'environment'
		this._stopScanning()
		this._startScanning()
	}

	_startScanning() {
		this.scanning = true
		this._startStreaming()
	}

	_stopScanning() {
		this.scanning = false
		this._stopStreaming()
	}

	_startStreaming() {
		navigator.mediaDevices.getUserMedia({ video: { facingMode: this.facingMode } }).then((stream) => {
			this.video.srcObject = stream
			this.video.setAttribute("playsinline", true)
			this.video.play()
			requestAnimationFrame(this._tick.bind(this))
		})
	}

	_stopStreaming() {
		const stream = this.video.srcObject
		if (!stream) return

		const tracks = stream.getTracks()
		tracks.forEach((track) => { track.stop() })
		this.video.srcObject = null
		this.canvas.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);
	}

	_tick() {
		if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
			const canvasElement = this.canvasTarget

			canvasElement.hidden = false
			canvasElement.height = this.video.videoHeight * canvasElement.width / this.video.videoWidth
			this.canvas.drawImage(this.video, 0, 0, canvasElement.width, canvasElement.height)

			const imageData = this.canvas.getImageData(0, 0, canvasElement.width, canvasElement.height)
			const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })
			if (code) {
				try {
					const url = new URL(code.data)
					const hostname = url.hostname.replace("www.", "")
					const compareHostname = window.location.hostname.replace("www.", "")
					if (hostname === compareHostname) {
						Turbo.visit(url.href)
					} else if (alertify) {
						alertify.error("Invalid QR Code.")
					}
				} catch (e) {
					console.warn("Invalid URL in QR code:", code.data)
				}
				this.close()
			}
		}

		if (this.video) {
			requestAnimationFrame(this._tick.bind(this))
		}
	}

	get canvas() {
		return this.canvasTarget.getContext("2d")
	}

	get isPreview() {
		return document.documentElement.hasAttribute("data-turbo-preview")
	}
}
