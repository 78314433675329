import { Controller } from "stimulus"
import { useMatchMedia, useMutation } from "stimulus-use"

export default class extends Controller {
	static targets = [ "list", "room", "link" ]
	static classes = [ "selected" ]
	static values = { docked: Boolean }

	connect() {
		if (!this.hasListTarget) return

		this.#setSelectedRoom()
		useMutation(this, { element: this.listTarget, childList: true })

		if (this.dockedValue) useMatchMedia(this, { mediaQueries: { mobile: this.#mobileBreakpoint() } })
	}

	mutate(entries) {
		entries.forEach((entry) => {
			if (entry.addedNodes.length > 0) {
				const node = Array.from(entry.addedNodes).find((node) => node.dataset.update && JSON.parse(node.dataset.update))
				if (node) {
					this.#setSelectedRoom()
					// additionally we need to check if we are on dock, because if we are we need to change
					// the link options because by default it will assume that is a turbo frame request
					// but for dock elements we want a turbo stream request
					if (this.dockedValue) this.#setupRoomLink(node)
				}
			}
		})
	}

	click(event) {
		// we start by de-selecting any selected room
		this.#removeSelectedRoom()
		this.#setSelectedRoomForLink(event.currentTarget)
	}

	roomTargetConnected(element) {
		const isUpdate = JSON.parse(element.dataset.update)
		if (!isUpdate) return

		this.#setSelectedRoom()
	}

	isMobile() {
		// OK, if we are on mobile all the links should do a normal visit to the URL
		this.linkTargets.forEach((element) => this.#setTurboFrameRequest(element))
	}

	notMobile() {
		// if we are not in mobile we should set that we are using a Turbo Stream request
		this.linkTargets.forEach((element) => this.#setTurboStreamRequest(element))
	}

	#setSelectedRoom() {
		// we start by de-selecting any room that could be selected
		this.#removeSelectedRoom()

		// we need to remove any format defined because otherwise we will have wrong matches
		// but we also need the format because sometimes we need to redirect to a specific format,
		// like when we click in a notification
		const href = window.location.href.replace(".html", "")

		// and then we compare the links for each room against the current href to see
		// if the room is selected or not
		this.linkTargets.forEach((link) => {
			if (link.href === href) this.#setSelectedRoomForLink(link)
		})
	}

	#removeSelectedRoom() {
		this.roomTargets.forEach((element) => element.classList.remove(...this.selectedClasses))
	}

	#setSelectedRoomForLink(link) {
		// we don't want the selection behavior if we are accessing rooms from a dock
		if (this.dockedValue) return

		const roomContainer = link.closest('[data-chat--rooms-target="room"]')
		if (roomContainer) roomContainer.classList.add(...this.selectedClasses)
	}

	#setupRoomLink(node) {
		const linkElement = node.querySelector(`[data-${this.identifier}-target="link"]`)
		if (!linkElement) return

		if (this.#isMobile()) {
			this.#setTurboFrameRequest(linkElement)
		} else {
			this.#setTurboStreamRequest(linkElement)
		}
	}

	#setTurboStreamRequest(element) {
		element.setAttribute("data-turbo-stream", "")
		element.removeAttribute("data-turbo-frame")
	}

	#setTurboFrameRequest(element) {
		element.removeAttribute("data-turbo-stream")
		element.setAttribute("data-turbo-frame", "_top")
	}

	#mobileBreakpoint() {
		return "(max-width: 1023px)"
	}

	#isMobile() {
		return window.matchMedia(this.#mobileBreakpoint()).matches
	}
}
