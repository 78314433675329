// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { Dropdown } from "tailwindcss-stimulus-components"
import { Modal } from "tailwindcss-stimulus-components"
import { Toggle } from "tailwindcss-stimulus-components"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('toggle', Toggle)
