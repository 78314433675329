import { Controller } from "stimulus"

export default class extends Controller {
  // Detects when a CSS sticky element gets stuck.
  // The value for top needs to be -1px, otherwise intersection isn't detected

  initialize() {
    this.observer = null

    this.watchIntersection();
  }

  connect() {
    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer.unobserve(this.element)
  }

  watchIntersection() {
    this.observer = new IntersectionObserver( ([e]) => {
      const boudingBox = e.boundingClientRect
      const topReached = (boudingBox.top === this.topPosition)

      this.element.classList.toggle(this.classForStuckElement, topReached)
    }, { threshold: this.thresholdSteps })
  }

  get classForStuckElement() {
    return 'is-stuck'
  }

  get thresholdSteps() {
    return Array(11).fill().map( (_, i) => i*.1)
  }

  get topPosition() {
    const elementStyle = window.getComputedStyle(this.element)
    const elementTop = elementStyle.getPropertyValue('top')

    return parseInt( elementTop )
  }
}
