import { Controller } from "stimulus"
import { useMatchMedia } from "stimulus-use"

/**
 * Controller that will check if we are on a mobile device or not and accordingly with that
 * information we will enable/disable Turbo features, because this can be used in links or
 * forms we need to have different behaviors, for forms we need to toggle `data-turbo` between
 * `true` and `false`, for other elements (that should be links) we need to work with
 * `data-turbo-stream` attribute
 */
export default class extends Controller {
	static values = { native: Boolean }

	connect() {
		if (this.nativeValue) {
			this.#handleNative()
		} else {
			useMatchMedia(this, { mediaQueries: { mobile: "(max-width: 1023px)" } })
		}
	}

	isMobile() {
		if (this.#isForm()) {
			this.element.setAttribute("data-turbo", false)
		} else {
			this.element.removeAttribute("data-turbo-stream")
		}
	}

	notMobile() {
		this.element.setAttribute(this.#isForm() ? "data-turbo" : "data-turbo-stream", true)
	}

	#isForm() {
		return this.element.nodeName === "FORM"
	}

	#handleNative() {
		if (!this.#isForm()) this.element.removeAttribute("data-turbo-stream")
	}
}
