import { Controller } from "stimulus"

export default class extends Controller {
	open(e) {
		e.preventDefault()

		this.sidebar.classList.add(this.openClass)
	}

	close(e) {
		e.preventDefault()

		this.sidebar.classList.remove(this.openClass)
	}

	get sidebar() {
		return document.getElementById("sidebar")
	}

	get openClass() {
		return "is-open"
	}
}
