import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["input", "counter", "max"]
	static values = { maxLength: Number, backwards: Boolean }
	static classes = [ "backwardsThreshold" ]

	initialize() {
		this.update = this.#onUpdate.bind(this)
	}

	connect() {
		if (!this.backwardsValue) this.#setMax()
		this.#onUpdate()
		this.inputTarget.addEventListener("input", this.update)
	}

	disconnect() {
		this.inputTarget.removeEventListener("input", this.update)
	}

	#onUpdate() {
		this.counterTarget.innerHTML = this.count
		if (this.backwardsValue) this.#checkBackwardsThreshold()
	}

	#setMax() {
		if (this.hasMaxTarget && this.maxlength) this.maxTarget.innerHTML = "/" + this.maxlength.toString()
	}

	#checkBackwardsThreshold() {
		if (!this.hasBackwardsThresholdClass) return

		if (this.count > 0) {
			this.counterTarget.classList.remove(...this.backwardsThresholdClasses)
			this.inputTarget.setCustomValidity('')
		} else {
			this.counterTarget.classList.add(...this.backwardsThresholdClasses)
			this.inputTarget.setCustomValidity('Maximum number of characters reached')
		}
	}

	get maxlength() {
		if (this.maxLengthValue > 0) return this.maxLengthValue

		return this.inputTarget.getAttribute("maxlength")
	}

	get count() {
		if (this.backwardsValue) return this.maxlength - this.inputTarget.value.length

		return this.inputTarget.value.length
	}
}
