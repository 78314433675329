import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ 'timezoneSelect', 'time' ]
	static values = {
		time: String,
		format: { type: String, default: 'hour24' },
		timezone: { type: String, default: 'Europe/Lisbon' }
	}

	initialize() {
		this.date = new Date()
		this.hour12 = this.formatValue == 'hour12'
	}

	connect() {
		this.start()
	}

	disconnect() {
		this.stop()
	}

	start() {
		this.render()

		this.worker = setInterval(() => {
			this.date.setSeconds( this.date.getSeconds() + 1)
		}, 1000)

		this.timer = setInterval(() => {
			this.render()
		}, 60000)
	}

	stop() {
		clearInterval(this.timer)
		clearInterval(this.worker)
	}

	render() {
		this.timeTarget.innerText = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', timeZone: this.timezoneValue, hour12: this.hour12 }).format(this.date)
	}

	setTimezone(e) {
		this.timezoneValue = e.target.options[e.target.selectedIndex].dataset.official
		this.render()
	}
}
