import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "card", "savedCard", "submit", "feedback", "cardInFile", "markDefault" ]
	static values = { secret: String }

	initialize() {
		this.submitLabel = this.submitTarget.value
	}

	connect() {
		if (this.element.tagName !== "FORM") {
			console.error("This controller can only be used in a <form> tag")
			return
		}

		if (this.#loadStripe()) {
			this.#initializeCardElement()
			this.submitTarget.classList.add("is-disabled")
			this.onSavedCardSelect()
		}
	}

	onSubmit(event) {
		event.preventDefault()

		this.submitTarget.classList.add("is-disabled")
		this.submitTarget.value = this.submitTarget.dataset.disableWith

		const savedCard = this.savedCard
		let params
		if (savedCard) {
			params = { payment_method: savedCard.value }
		} else {
			params = { payment_method: { card: this.cardField } }
			if (this.hasMarkDefaultTarget && this.markDefaultTarget.checked) params.setup_future_usage = "off_session"
		}

		this
			.stripe
			.confirmCardPayment(this.secretValue, params)
			.then((result) => {
				if (result.error) {
					if (this.hasFeedbackTarget) this.feedbackTarget.innerText = result.error.message
					this.submitTarget.value = this.submitLabel
				} else {
					// we will send the payment method ID to server so that we can check if we need
					// to mark this payment method as the default charge method, we check if the element
					// really exists just in case
					if (this.hasCardInFileTarget) this.cardInFileTarget.value = result.paymentIntent.payment_method

					this.element.submit()
				}
			})
	}

	onCardChange(event) {
		this.#deselectSavedCard()
		this.#clearFeedbackMessage()
		this.submitTarget.classList.toggle("is-disabled", !event.complete)
		this.submitTarget.toggleAttribute("disabled", !event.complete)
	}

	onSavedCardSelect() {
		if (this.savedCard) {
			this.submitTarget.classList.remove("is-disabled")
			this.submitTarget.removeAttribute("disabled")
		}
		this.#clearFeedbackMessage()
	}

	#loadStripe() {
		if (!App.Credentials.Stripe) {
			console.error("Stripe key not set, exiting")
			return false
		}

		if (typeof window.Stripe === "undefined") {
			console.error("Stripe.js library not found")
			return false
		}

		// in this case we really want to force English because all our interface is only available in that
		// locale, by default Stripe has `auto` but that will change only that content to the user locale
		this.stripe = window.Stripe(App.Credentials.Stripe.key, { locale: "en", apiVersion: App.Credentials.Stripe.apiVersion })

		return true
	}

	#initializeCardElement() {
		// we need to tell Stripe to load Montserrat from Google Fonts
		const elements = this.stripe.elements({
			fonts: [ { cssSrc: "https://fonts.googleapis.com/css?family=Montserrat" } ]
		})

		// now we need to define default styles to card element
		const styles = {
			base: {
				color: "#fff",
				fontFamily: "Montserrat, sans-serif",
				fontSize: "14px",
				fontWeight: "normal",
				fontSmoothing: "antialised",
				lineHeight: "20px",
				'::placeholder': {
					color: "#8a8a9f"
				}
			},
			invalid: {
				color: "#e75557",
				iconColor: "#e75557"
			}
		}

		const field = elements.create("card", { style: styles, hidePostalCode: true })
		field.mount(this.cardTarget)
		this.cardField = field
		this.cardField.addEventListener("change", this.onCardChange.bind(this))
	}

	#deselectSavedCard() {
		const savedCard = this.savedCard
		if (savedCard) savedCard.checked = false
	}

	#clearFeedbackMessage() {
		if (this.hasFeedbackTarget) this.feedbackTarget.innerText = null
	}

	get savedCard() {
		return this.savedCardTargets.find((savedCard) => savedCard.checked)
	}
}
