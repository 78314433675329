import { Controller } from "stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
	static debounces = [ "autogrow" ]

	initialize() {
		this.onInput = this.autogrow.bind(this)
		this.onSubmitEnd = this.#onSubmitEnd.bind(this)
	}

	connect() {
		useDebounce(this, { wait: 150 })
		this.element.addEventListener("input", this.onInput)
		this.autogrow()
		if (this.form) this.form.addEventListener("turbo:submit-end", this.onSubmitEnd)
	}

	disconnect() {
		this.element.removeEventListener("input", this.onInput)
		if (this.form) this.form.removeEventListener("turbo:submit-end", this.onSubmitEnd)
	}

	autogrow() {
		this.element.style.height = "auto"
		this.element.style.height = `${this.element.scrollHeight}px`
	}

	#onSubmitEnd() {
		this.element.style.height = "auto"
	}

	get form() {
		return this.element.closest("form")
	}
}
