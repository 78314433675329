import { Controller } from "stimulus"
import { useMutation } from "stimulus-use"

export default class extends Controller {
	static values = { url: String, scopeUrl: String }
	static targets = [ "button" ]

	connect() {
		// basically if our current page includes some part of the controller url value
		// we will assume that we are on notifications page so we will not allow the drawer controller
		// to work and we just convert it in a regular selected button
		if (location.href.includes(this.scopeUrlValue)) {
			// we will set the active class on the element and remove any `data-action` that could be
			// set in the button target
			this.#setActiveClass()
			this.buttonTarget.dataset.action = null
			return
		}

		useMutation(this, { attributes: true })
	}

	mutate(entries) {
		const attribute = "data-drawer-open-value"
		entries.forEach((entry) => {
			// for start we just want to check attributes changes and only for attribute `data-drawer-open-value`
			if (entry.type === "attributes" && entry.attributeName === attribute) {
				// OK, now that we found our element we will check if the attribute value is true
				// we only want to run this code when the drawer is about to be open
				const isOpen = JSON.parse(entry.target.getAttribute(attribute))
				if (isOpen) this.#loadNotifications()
			}
		})
	}

	#loadNotifications() {
		fetch(this.urlValue, { headers: { Accept: "text/vnd.turbo-stream.html" } })
			.then(r => r.text())
			.then((html) => {
				Turbo.renderStreamMessage(html)
			})
	}

	#setActiveClass() {
		const activeClass = this.element.dataset.drawerActiveClass || "nav-list__item--active"
		this.element.removeAttribute("data-drawer-active-class")
		this.element.classList.add(activeClass)
	}
}
