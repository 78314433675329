import { Controller } from "stimulus"

export default class extends Controller {
	connect() {
		if (this.element.tagName !== "FORM") {
			console.error("This controller can only be used in a <form> tag")
			return
		}

		this.element.setAttribute('novalidate', 'novalidate')

		this.submitted = false
	}

	validate(event) {
		event.preventDefault()

		if (this.submitted) {
			return
		}

		const form = event.target

		if (!form.checkValidity()) {
			for (const element of form.elements) {
				if (!element.checkValidity()) {
					const parentDropdown = element.closest('[data-controller="dropdown"]')

					if (parentDropdown && parentDropdown.getAttribute('aria-expanded') == 'false') {
						parentDropdown.querySelector('[data-dropdown-target="button"]').click()

						setTimeout(() => {
							element.reportValidity()
						}, 300)
						return
					} else {
						element.reportValidity()
						return
					}
				}
			}
		} else {
			this.submitted = true

			form.dispatchEvent(new CustomEvent('submit', { bubbles: true }))

			setTimeout(() => {
				this.submitted = false;
			}, 100)
		}
	}
}
