// Enables Selectize on select fields

import { Controller } from "stimulus"
import ScriptLoader from "../utils/scriptloader"

export default class extends Controller {
	static targets = [ "select" ]
	static values = {
			plugins:  { type: Array,   default: [] },
			create:   { type: Boolean, default: false },
			options:  { type: Array,   default: [] },
			maxItems: Number,
			remote:   String,
	}

	initialize() {
		this.destroy = this._destroy.bind(this)
	}

	connect() {
		// turns out Selectize removes the required attribute from the original input
		// on initialization, so we need to store this manually and restore it when
		// initializing it again (such as on remoteValueChanged)
		this.required = false
		this.#setup()

		document.addEventListener('turbo:before-cache', this.destroy)
	}

	disconnect() {
		document.removeEventListener('turbo:before-cache', this.destroy)
	}

	remoteValueChanged() {
		if (this.selectInput !== undefined) {
			// when the remote value changes we will destroy the selectize element, then we will
			// clear all the options in the select itself because a change in remote value signals
			// a major change in the possible content in select so we clear the options, finally
			// we just setup selectize again
			this.selectInput.selectize.destroy()
			this.selectInput.innerHTML = ""
			if (this.required) { this.selectInput.setAttribute('required', '') }
			this.#setup()

			if (this.selectInput.hasAttribute('deselectize')) {
				this.selectInput.selectize.lock()
			} else {
				this.selectInput.selectize.unlock()
			}
		}
	}

	#setup() {
		this.selectInput = this.hasSelectTarget ? this.selectTarget : this.element

		ScriptLoader.loadScript("selectize", () => {
			let options = {
				plugins: this.pluginsValue,
				closeAfterSelect: true,
				createOnBlur: true,
				create: this.createValue
			}

			if (this.selectInput.getAttribute('required')) { this.required =  true }

			if (this.hasMaxItemsValue) { options['maxItems'] = this.maxItemsValue }
			if (this.optionsValue.length > 0 ) { options['options'] = this.optionsValue }

			if (!this.createValue && this.hasRemoteValue) {
				options["load"] = async (query, callback) => {
					const location = window.location
					const url = new URL(this.remoteValue, `${location.protocol}//${location.host}`)
					url.searchParams.set("term", query)
					const response = await fetch(url.toString(), { method: "GET", headers: { "Content-Type": "application/json", "X-CSRF-Token": this.csrfToken } })
					if (response.ok) {
						callback(await response.json())
					} else {
						callback()
					}
				}
			}

			$(this.selectInput).selectize(options).on('change', (event) => {
				this.selectInput.form.dispatchEvent(new Event('change'))
			});
		});
	}

	_destroy() {
		if (this.selectInput.selectize !== undefined) this.selectInput.selectize.destroy()
	}

	get csrfToken() {
		const meta = document.querySelector("meta[name=csrf-token]")
		return meta && meta.content
	}
}
