import { Dropdown } from "tailwindcss-stimulus-components"

export default class Drawer extends Dropdown {
	initialize() {
		this.openClass = "has-drawer-open"
	}

	connect() {
		super.connect()
		this.dispatch("connected")
	}

	enableDrawer() {
		this.element.classList.remove(this.activeClass)
		this.element.removeAttribute("inert")
	}

	disableDrawer() {
		this.element.classList.add(this.activeClass)
		this.element.setAttribute("inert", true)
	}

	_show(cb) {
		super._show(cb)
		document.body.classList.add(this.openClass)
		this.dispatch("show")
	}

	_hide(cb) {
		super._hide(cb)
		document.body.classList.remove(this.openClass)
		this.dispatch("hide")
	}
}
