// app/javascript/channels/notification_channel.js
import consumer from "./consumer"

consumer.subscriptions.create(
	"Noticed::NotificationChannel",
	{
		received(data) {
			const unreadElements = document.querySelectorAll("[data-unread-notifications]")
			const label = data.unread_notifications > 99 ? "99+" : data.unread_notifications
			unreadElements.forEach((element) => {
				element.classList.remove("hidden")
				element.innerText = label
				element.setAttribute("title", label)
			})
		}
	}
)
