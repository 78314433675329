import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
		this.inputs = Array.from(
			this.element.querySelectorAll('input[required]')
		)

		const filledInputs = this.inputs.filter(i => i.value !== '')

		if( filledInputs.length > 0 ) {
			this.inputs
				.filter(i => i.value == '')
				.forEach(i => (i.required = false));
		}
	}

	checkRequired(event) {
		this.inputs
			.filter(i => i !== event.target)
			.forEach(i => (i.required = !event.target.value.length));
	}
}
