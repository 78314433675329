import { Controller } from "stimulus"
import ScriptLoader from "../utils/scriptloader"

export default class extends Controller {
	static targets = [ 'trigger' ]
	static values = {
		events: { type: Array, default: [] },
		range: { type: Array, default: [] },
		current: { type: String, default: '' },
		searchFormId: { type: String, default: '' }
	}

	initialize() {
		this.destroy = this._destroy.bind(this)
	}

	connect() {
		ScriptLoader.loadScript('pikaday', () => {
			let self = this

			const el = document.createElement('input')
			el.classList.add('hidden')
			el.setAttribute('id', 'schedule-picker-input')
			this.element.appendChild(el)

			const queryString = window.location.search
			const urlParams = new URLSearchParams(queryString)

			let firstDate = null
			let lastDate = null

			if (self.rangeValue.length > 0) {
				firstDate = new Date(this.rangeValue[0])
				lastDate = new Date(this.rangeValue[this.rangeValue.length - 1])
				var rangeDates = self.rangeValue.map(dateString => new Date(dateString).toISOString().slice(0,10))
			}

			/* Older versions of Safari don't like dashes, so we need to replace those */
			let defaultDate = this.currentValue != ''  ? new Date(this.currentValue.replace(/-/g, "/")) : firstDate

			if (urlParams.has('date')) {
				const timestamp = Date.parse(urlParams.get('date'))

				if (isNaN(timestamp) == false) {
					defaultDate = new Date(urlParams.get('date'))
				}
			}

			this.instance = new Pikaday({
				field: document.getElementById('schedule-picker-input'),
				trigger: this.triggerTarget,
				defaultDate: defaultDate,
				setDefaultDate: firstDate,
				yearRange: firstDate && lastDate ? [firstDate.getFullYear(), lastDate.getFullYear()] : 10,
				minDate: firstDate,
				maxDate: lastDate,
				showDaysInNextAndPreviousMonths: true,
				enableSelectionDaysInNextAndPreviousMonths: true,
				events: this.eventsValue,
				onSelect: function(date) {
					self.insertParam( 'date', self.formatDate(date) )
				},
				disableDayFn: function(date) {
					if (self.rangeValue.length > 0) {
						return !rangeDates.includes( date.toISOString().slice(0,10) )
					} else {
						return false
					}
				}
			})

			document.addEventListener('turbo:before-cache', this.destroy)
		})
	}

	disconnect() {
		document.removeEventListener('turbo:before-cache', this.destroy)
	}

	formatDate(date) {
		const year = date.toLocaleString('default', { year: 'numeric' });
		const month = date.toLocaleString('default', { month: '2-digit' });
		const day = date.toLocaleString('default', { day: '2-digit' });

		return [year, month, day].join('-');
	}

	formatPickerDate(date) {
		const year = date.toLocaleString('default', { year: 'numeric' });
		const month = date.toLocaleString('default', { month: 'short' });
		const day = date.toLocaleString('default', { day: '2-digit' });
		const weekday = date.toLocaleString('default', { weekday: 'short' });

		return [weekday, month, day, year].join(' ');
	}

	insertParam(key, value) {
		if (this.searchFormIdValue != '') {
			let form = document.getElementById(this.searchFormIdValue);
			form.querySelector(`input[name=${key}]`).value = value
			form.requestSubmit();
		} else {
			const url = new URL(window.location);
			url.searchParams.set(key, value);
			//TODO: allow the controller to receive information about a turbo frame, and apply
			// that to the visit
			Turbo.visit(url.toString());
		}
	}

	_destroy() {
		if (this.instance) {
			this.instance.destroy()
			this.instance = null
		}
	}
}
