import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item", "container", "viewport", "pagination", "previewer", "page", "total", "previous", "next", "thumbnail" ]
  static classes = [ "itemSelected", "disabledPagination" ]

  initialize() {
    this.slideIndex = this._guessSlide()
    this.onTouchEnd = this._onTouchEnd.bind(this)

    this.onThumbnailClick = this._onThumbnailClick.bind(this)

    if (this.hasThumbnailTarget) {
      this.thumbnailTargets.forEach(t => t.addEventListener('click', this.onThumbnailClick))
    }

  }

  connect() {
    this._buildPagination()
    this._buildPreviewer()
    this._move()

    // we will check if the device can `hover`, and will only set the overflow for that
    // devices, other devices should be touch so we want them to be able to use gestures
    if (window.matchMedia('(hover: hover)').matches) {
      this.viewportTarget.classList.add('overflow-hidden')
    } else if (window.matchMedia('(hover: none) and (pointer: coarse)').matches) {
      this.viewportTarget.addEventListener('touchend', this.onTouchEnd)
    }
  }

  disconnect() {
    if (window.matchMedia('(hover: none) and (pointer: coarse)').matches) {
      this.viewportTarget.removeEventListener('touchend', this.onTouchEnd)
    }
    if (this.hasPaginationTarget) this.paginationTarget.remove()
    if (this.hasPreviewerTarget) this.previewerTarget.innerHTML = ''
  }

  prevSlide() {
    if (this._isFirst()) return
    this.slideIndex -= 1
    this._move()
  }

  nextSlide() {
    if (this._isLast()) return
    this.slideIndex += 1
    this._move()
  }

  moveTo(event) {
    this.currentSlide.dispatchEvent(new CustomEvent('carousel:slide.leaving'))

    const target = event.currentTarget
    const desiredSlide = parseInt(target.dataset.slide)
    if (desiredSlide < 1 || desiredSlide > this.totalSlides) return
    this.slideIndex = desiredSlide
    this._move()
  }

  _onTouchEnd() {
    // we will set a timout because sometimes we can miss the guessing
    setTimeout(() => {
      this.slideIndex = this._guessSlide()
      this._setCurrentSlider()
      this._setNavigationStatus()
    }, 250)
  }

  _move() {
    const slide = this.itemTargets[this.slideIndex - 1]

    if (slide) {
      this._clearSelection()
      this.viewportTarget.scrollTo({ left: slide.offsetLeft, behavior: 'auto' })
      slide.classList.add(this.itemSelectedClass)
    }

    this._setCurrentSlider()
    this._setNavigationStatus()
    this._handlePreview()
    this.currentSlide.dispatchEvent(new CustomEvent('carousel:slide.entering'))

    // REVIEW: we probably don't need this here, later check if we can clean up
    if (typeof player !== 'undefined') {
      if (player.getPlayerState() && player.getPlayerState() == 1) {
        player.stopVideo();
      }

      this._resetThumbnails();

      player.loadVideoById(slide.id);
      player.stopVideo();
    }
  }

  _setNavigationStatus() {
		let klass = 'is-disabled'

		if (this.hasDisabledPaginationClass) {
			klass = this.disabledPaginationClass
		}

    if (this.hasPreviousTarget) this.previousTarget.classList.toggle(klass, this._isFirst())
    if (this.hasNextTarget) this.nextTarget.classList.toggle(klass, this._isLast())
  }

  _isFirst() {
    return this.slideIndex === 1
  }

  _isLast() {
    return this.slideIndex === this.itemTargets.length
  }

  _buildPagination() {
    // we only build pagination we have a template for it
    if (this.hasPaginationTarget && this.itemTargets.length > 1) {
      const pagination = this.paginationTarget.content.cloneNode(true)
      this.containerTarget.appendChild(pagination)
      this._setCurrentSlider()
      this._setTotalSlider()
      this._setNavigationStatus()
    }
  }

  _buildPreviewer() {
    if (this.hasPreviewerTarget) {
      this.previewerTarget.innerHTML = ''
      this.itemTargets.forEach((item, index) => {
        // since we can only have one image in each slide we will look for an image, also if we don't find
        // any image we will ignore that button, eventually in the backend we will be requiring that a banner
        // always have an image so this shouldn't be a major problem
        const image = item.querySelector('img')
        if (image) {
          const previewButton = document.createElement('button')
          previewButton.classList.add('carousel-preview__item', 'animate__animated', 'animate__fast', 'animate__fadeIn', 'animate__delay', 'overflow-hidden')
          previewButton.type = 'button'
          previewButton.dataset.slide = index + 1
          previewButton.dataset.action = 'click->carousel#moveTo'

          if (image.dataset.title) previewButton.dataset.title = image.dataset.title

          const previewImage = document.createElement('img')
          previewImage.classList.add('h-full', 'object-cover', 'w-full', 'transition-all', 'duration-500', 'transform', 'hover:scale-110')
          previewImage.alt = ''
          previewImage.src = image.src

          previewButton.appendChild(previewImage)
          this.previewerTarget.appendChild(previewButton)
        }
      })
    }
  }

  _handlePreview() {
    if (this.hasPreviewerTarget) {
      // grab any preview that is selected and store it, so that we can later use it
      const selectedSlide = this.previewerTarget.querySelector(`.${this.selectedPreviewClass}`)

      // now we hide the current slide item
      const currentSlide = this.previewerTarget.querySelector(`[data-slide="${this.slideIndex}"]`)
      if (currentSlide) currentSlide.classList.add(this.selectedPreviewClass)
      if (selectedSlide) selectedSlide.classList.remove(this.selectedPreviewClass)
    }
  }

  _setCurrentSlider() {
    // NOTE: this sliders are meant to hold very few elements, eventually we shouldn't have
    // more than 4, so this is a shortcut instead of having a function to check if we should pad with zero
    // the number supplied
    if (this.hasPageTarget) this.pageTarget.innerText = this._checkIndexZeroPadding(this.slideIndex)
  }

  _setTotalSlider() {
    if (this.hasTotalTarget) this.totalTarget.innerText = this._checkIndexZeroPadding(this.itemTargets.length)
  }

  _checkIndexZeroPadding(index) {
		return this.totalSlides >= 10 ? String(index).padStart(2,'0') : index
  }

  _guessSlide() {
    // basic validation if the `scrollLeft` is zero, it's the first
    const scrollLeft = this.viewportTarget.scrollLeft
    if (scrollLeft === 0) return 1

    const itemsPosition = this.itemTargets.map((item) => item.offsetLeft)
    const closest = itemsPosition.reduce((original, compare) => {
      return Math.abs(compare - scrollLeft) < Math.abs(original - scrollLeft) ? compare : original
    })

    return itemsPosition.indexOf(closest) + 1
  }

  _resetThumbnails() {
    this.itemTargets.forEach(t => {
      const thumbnail = t.querySelector('.video-banner-thumbnail')
      if (thumbnail) thumbnail.classList.remove('opacity-0')
    })
  }

  _clearSelection() {
    const selected = this.viewportTarget.querySelector(`.${this.itemSelectedClass}`)
    if (selected) selected.classList.remove(this.itemSelectedClass)
  }

  _onThumbnailClick(e) {

    if (player.getPlayerState() == 1) {

      e.target.classList.remove("opacity-0");
      player.stopVideo();

    } else {

      e.target.classList.add("opacity-0");
      player.playVideo();

    }

  }

  get currentSlide() {
    return this.itemTargets[this.slideIndex - 1]
  }

  get totalSlides() {
    return this.itemTargets.length
  }

  get selectedPreviewClass() {
    return 'carousel-preview__item--selected'
  }

}
