import { Controller } from "stimulus"

export default class extends Controller {
  static classes = [ "animation" ]
	static targets = [ "animation" ]

  connect() {
    if (this.hasAnimationClass) {
			this.animationElement = this.hasAnimationTarget ? this.animationTarget : this.element

      this.animationElement.classList.add(this.animationClass)
      this.animationElement.addEventListener("animationend", () => this.animationElement.classList.remove(this.animationClass))
    }
  }

  onSuccess(event) {
    const response = event.detail[0]
    const elements = document.querySelectorAll(`[data-target="${response.target}"]`)
    elements.forEach(element => element.outerHTML = response.result)
  }

	refreshPage() {
		window.location.reload();
	}
}
