import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
		let accordion = this.element.querySelector('.accordion_wrapper');

		if( accordion && !accordion.dataset.loaded ) {
			accordion.querySelectorAll(".accordion__title").forEach((el) => this._upgradeTitle(el))
			accordion.querySelectorAll(".accordion__body").forEach((el) => this._moveElement(el))

			accordion.dataset.loaded = true
		}
  }

	_moveElement(el) {
		let newEl = document.createElement('div')
		newEl.classList.add('hidden', 'accordion__body');
		newEl.setAttribute('data-dropdown-target', 'menu');
		newEl.innerHTML = el.innerHTML;

		el.previousElementSibling.appendChild(newEl);
		el.remove();
	}

	_upgradeTitle(el) {
		let newEl = '<h3 data-action="click->dropdown#toggle" data-dropdown-target="button" role="button" tabindex="0">' + el.innerHTML + "</h3>";
		el.innerHTML = newEl;
		el.setAttribute('aria-exapnded', false);
		el.setAttribute('aria-haspopup', true);
		el.setAttribute('data-controller', 'dropdown');
	}
}
