import { Controller } from "stimulus"

export default class extends Controller {
  onNavigate(event) {
    const linkElement = event.currentTarget
    const isRemote = JSON.parse(linkElement.dataset.remote || false)
    if (isRemote) {
      const url = new URL(linkElement.href)
      history.replaceState(
        this._buildState(url.searchParams),
        document.title,
        url.search === '' ? location.pathname : url.search
      )
    }
  }

  _buildState(searchParams) {
    const state = {}
    for (let entry of searchParams) {
      state[entry[0]] = entry[1]
    }
    return state
  }
}
