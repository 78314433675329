import { Controller } from "stimulus"
import { useIntersection, useDebounce } from "stimulus-use"

export default class extends Controller {
	static debounces = [ "click" ]

	connect() {
		useIntersection(this, { threshold: 0 })
		useDebounce(this, { wait: 300 })
	}

	appear() {
		this.element.dispatchEvent(new Event("autoclick", { bubbles: true }))
		this.element.style.display = "none"
		this.click()
	}

	click() {
		this.element.click()
	}
}
