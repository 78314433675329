// After person chooses an option on a select make a turbo frame request
// E.g. Form to create mentorships depend on the village space chosen (different space module config)
import { Controller } from "stimulus"

export default class extends Controller {
	static values = { path: String, frame: String, param: String }

	connect() {
		this.element.addEventListener('change', () => {
			Turbo.visit(`${this.pathValue}?${this.element.getAttribute('name')}=${this.element.value}`, { frame: this.frameValue })
		})
	}
}
