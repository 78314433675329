// Heavly inspired on https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/modal.js,
// but handling modal opening with some differences.
//
// This example controller works with specially annotated HTML like:
//
//<!-- Dialog Toggle -->
// <button data-components--dialog-toggle="example-dialog" type="button">Open dialog</button>
//
//<!-- Dialog -->
//<div data-controller="components--dialog" data-components--dialog-allow-background-close="false" id="example-dialog">
	//<!-- Dialog Container -->
	//<div data-components--dialog-target="container" data-action="click->components--dialog#closeBackground keyup@document->components--dialog#closeWithKeyboard" class="hidden animated fadeIn fixed inset-0 overflow-y-auto flex items-center justify-center" style="z-index: 9999;">
		//<!-- Dialog Inner Container -->
		//<div class="max-h-screen w-full max-w-lg relative">
		// ...
		//</div>
	//</div>
//</div>

import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = ['container'];

	initialize() {
		this.globalListener = this._globalListener.bind(this)
	}

	connect() {
		// The class we should toggle on the container
		this.toggleClass = this.data.get('class') || 'hidden';
		this.stateClass = 'is-open';

		// The ID of the background to hide/remove
		this.backgroundId = this.data.get('backgroundId') || 'dialog-background';

		// The HTML for the background element
		this.backgroundHtml = this.data.get('backgroundHtml') || this._backgroundHTML();

		// Let the user close the dialog by clicking on the background
		this.allowBackgroundClose = (this.data.get('allowBackgroundClose') || 'true') === 'true';

		// Prevent the default action of the clicked element (following a link for example) when closing the dialog
		this.preventDefaultActionClosing = (this.data.get('preventDefaultActionClosing') || 'true') === 'true';

		document.addEventListener('click', this.globalListener);

		if (this.data.get("auto-open")) {
			this.open();
		}
	}

	disconnect() {
		document.removeEventListener('click', this.globalListener);
		const backdrop = document.getElementById(this.backgroundId);
		if (backdrop) backdrop.remove();
		this.unlockScroll();
	}

  open(e) {
    // Check for event, because auto-open modals don't have one
    if (e) {
      if (e.target.dataset['components-DialogToggle'] == this.element.id) {
        e.preventDefault();
      }

			if (e.target.blur) {
				e.target.blur();
			}
		}

		// Lock the scroll and save current scroll position
		this.lockScroll();

		// Unhide the dialog
		this.containerTarget.classList.remove(this.toggleClass);
		this.containerTarget.classList.add(this.stateClass);

		// Insert the background
		if (!this.data.get("disable-backdrop")) {
			document.body.insertAdjacentHTML('beforeend', this.backgroundHtml)
		}
	}

  close(e) {
    if (e && this.preventDefaultActionClosing) {
      e.preventDefault();
    }

		// Unlock the scroll and restore previous scroll position
		this.unlockScroll();

		// Hide the dialog
		this.containerTarget.classList.add(this.toggleClass);
		this.containerTarget.classList.remove(this.stateClass);

    // Remove the background
    let background = document.getElementById(this.backgroundId);
    if (background) { background.remove() };
  }

	closeBackground(e) {
		if (this.allowBackgroundClose && e.target === this.containerTarget) {
			this.close(e);
		}
	}

	closeWithKeyboard(e) {
		if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
			this.close(e);
		}
	}

	_globalListener(e) {
		let toggler = e.target.dataset['components-DialogToggle'];

		if (!toggler) return;
		if (toggler != this.element.id) return;

		this.open(e);
	}

	_backgroundHTML() {
		return `<div id="${this.backgroundId}" class="fixed top-0 left-0 w-full h-full" style="background-color: rgba(0, 0, 0, 0.8); z-index: 9998;"></div>`;
	}

	lockScroll() {
		// We only lock scroll if another component hasn't locked it yet
		if (!document.body.classList.contains('is-locked-scroll')) {
			// Add right padding to the body so the page doesn't shift
			// when we disable scrolling
			const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
			document.body.style.paddingRight = `${scrollbarWidth}px`;

			// Save the scroll position
			this.saveScrollPosition();

			// Add classes to body to fix its position
			document.body.classList.add('is-locked-scroll');

			// Add negative top position in order for body to stay in place
			document.body.style.top = `-${this.scrollPosition}px`;
		}
	}

	unlockScroll() {
		if (document.body.classList.contains('is-locked-scroll')) {
			// Remove tweaks for scrollbar
			document.body.style.paddingRight = null;

			// Remove classes from body to unfix position
			document.body.classList.remove('is-locked-scroll');

			// Restore the scroll position of the body before it got locked
			this.restoreScrollPosition();

			// Remove the negative top inline style from body
			document.body.style.top = null;

			// Remove saved scroll position
			this.scrollPosition = null
		}
	}

	saveScrollPosition() {
		this.scrollPosition = window.pageYOffset || document.body.scrollTop;
	}

	restoreScrollPosition() {
		document.documentElement.scrollTop = this.scrollPosition;
	}
}
