import { Controller } from "stimulus"

export default class extends Controller {
	static classes =  ['scrolled']
	static values = { threshold: Number }

	initialize() {
		this.ticking = false
		this.lastScrollY = window.scrollY
		this.hasScrolledDown = false
		this.hasScrolledUp = false
		this.handleScroll = this._handleScroll.bind(this)
	}

	connect() {
		window.addEventListener('scroll', this.handleScroll)
	}

	disconnect() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	_handleScroll() {
		const position = window.scrollY
		const direction = this.lastScrollY > position ? 'up' : 'down'
		this.lastScrollY = position

		if (!this.ticking) {
			window.requestAnimationFrame(() => {

				// REVIEW: definetely not generic and needs to be reviewd

				if (position > this.lastScrollTop && position > this.thresholdValue && !this.hasScrolledDown) {
					document.body.classList.add(...this.scrolledClasses)

					this.hasScrolledUp = false
					this.hasScrolledDown = true
				} else if (position < this.lastScrollTop  && !this.hasScrolledUp) {
					document.body.classList.remove(...this.scrolledClasses)

					this.hasScrolledUp = true
					this.hasScrolledDown = false
				}
				this.lastScrollTop = position;

				this.ticking = false
			})
			this.ticking = true
		}
	}
}
