import { Controller } from "stimulus"
import * as Utils from "../utils/common"

export default class extends Controller {
  static targets = [ "button", "stage", "modal" ]
  static values = { previewUrl:String, provider:String, embed:String }

  initialize() {
    this.player = null
    this.onSlideLeaving = this._onSlideLeaving.bind(this)
    this.onSlideEntering = this._onSlideEntering.bind(this)
    this.onOverlayClose = this._onOverlayClose.bind(this)
  }

  connect() {
    // if Vimeo isn't available, make it
    if (!this.vimeoAvailable) {
      let tag = document.createElement('script');
      let self = this;
      tag.onload = function () {
        self._createBackgroundVideo()
      }
      tag.src = "https://player.vimeo.com/api/player.js";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      this._createBackgroundVideo()
    }

    // because we need to play/pause any background video when we are moving from
    // slides, otherwise we risk to kill user devices
    this.element.addEventListener('carousel:slide.entering', this.onSlideEntering)
    this.element.addEventListener('carousel:slide.leaving', this.onSlideLeaving)
  }

  disconnect() {
    // when we are leaving we make sure that we clean ourselves
    this.element.removeEventListener('carousel:slide.entering', this.onSlideEntering)
    this.element.removeEventListener('carousel:slide.leaving', this.onSlideLeaving)
  }

  onPlay(event) {
    // if for some reason we don't find out modal target we just let the link flow
    // as it should, if we have a modal available we will just prevent default behavior
    // of the link and insert an iframe in the modal
    if (!this.hasModalTarget) return

    event.preventDefault()

    this.modalTarget.classList.add(this.classForModalPlaying)
    this.modalPlayerContainer.innerHTML = `<iframe src="${Utils.sourceForVideoProvider(this.providerValue, this.embedValue)}" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" allow="autoplay; fullscreen; picture-in-picture;"></iframe>`

		this.element.closest(".relative").classList.add("z-70");
  }

  onClose() {
    this.modalTarget.addEventListener('transitionend', this.onOverlayClose)
    this.modalTarget.classList.remove(this.classForModalPlaying)
    this.modalPlayerContainer.innerHTML = ''

		this.element.closest(".relative").classList.remove("z-70");
  }

  _createBackgroundVideo() {
    // we need to check if we can create a background video player
    if (!this.showBackgroundVideo) return

    // we are checking if user is requesting data saving, if that's the case we will
    // just skip the background video for the user
    if (this._shouldSaveData()) return

    this.stageTarget.classList.add(this.classForPreviewPlaying)
    this.stageTarget.innerHTML = `<iframe src="${this.previewUrlValue}?background=1&autopause=0" allowfullscreen allow="autoplay; fullscreen; picture-in-picture;"></iframe>`
    this.player = new Vimeo.Player(this.stageTarget.querySelector('iframe'))
  }

  _onSlideLeaving() {
    // if (this.player) this.player.pause()
    this.stageTarget.classList.remove(this.classForPreviewPlaying)
  }

  _onSlideEntering() {
    this.player ?  this.player.play() : this._createBackgroundVideo()
    this.stageTarget.classList.add(this.classForPreviewPlaying)
  }

  _onOverlayClose() {
    this.modalTarget.removeEventListener('transitionend', this.onOverlayClose)
    this.modalPlayerContainer.innerHTML = ''
  }

  _shouldSaveData() {
    return 'connection' in navigator && navigator.connection.saveData
  }

  get modalPlayerContainer() {
    return this.modalTarget.querySelector('.player__canvas')
  }

  get vimeoAvailable() {
    return typeof Vimeo !== 'undefined'
  }

  get onScreen() {
    const boundingClientRect = this.element.getBoundingClientRect()
    return boundingClientRect.x === 0
  }

  get showBackgroundVideo() {
    return this.vimeoAvailable && this.hasStageTarget && this.previewUrlValue !== ''
  }

  get classForPreviewPlaying() {
    return 'player__preview--playing'
  }

  get classForModalPlaying() {
    return 'player__modal--playing'
  }
}
