import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["password"]

  password(e) {
    if (this.passwordTarget.type === "password") {
      this.passwordTarget.type = "text";
      e.currentTarget.querySelector(".icon-eye").classList.add("icon-eye--closed")

    } else {
      this.passwordTarget.type = "password";
      e.currentTarget.querySelector(".icon-eye").classList.remove("icon-eye--closed")
    }
  }
}