import { Controller } from "stimulus"

export default class extends Controller {
  // TODO improve render do make it more flexible,
  // allowing for different date formats

  static targets = [ "day", "hour", "minute", "second", "beforeTime", "pastTime" ]
  static values = {
    endDate: String,
    dayLabel: String,
    hourLabel: String,
    minuteLabel: String,
    secondLabel: String,
    leadingZero: Boolean
  }

  initialize() {
    this.timeRemaining = this._setTimeRemaining()
    this.timer = null
  }

  connect() {
    this.start()
  }

  disconnect() {
    this.stop()
  }

  start() {
    this.render()
    this.timer = setInterval(() => {
      this.timeRemaining -= 1000
      if (this.timeRemaining < 0) {
        clearInterval(this.timer)
        this._timeUp()
      } else {
        this.render()
      }
    }, 1000)
  }

  stop() {
    clearInterval(this.timer)
  }

  render() {
    if (this.hasDayTarget) {
      this.dayTarget.textContent = `${this.leadingZeroValue ? this._format(this.days) : this.days}${this.dayLabelValue}`
    }

    if (this.hasHourTarget) {
      this.hourTarget.textContent = `${this.leadingZeroValue ? this._format(this.hours) : this.hours}${this.hourLabelValue}`
    }

    if (this.hasMinuteTarget) {
      this.minuteTarget.textContent = `${this.leadingZeroValue ? this._format(this.minutes) : this.minutes}${this.minuteLabelValue}`
    }

    if (this.hasSecondTarget) {
      this.secondTarget.textContent = `${this.leadingZeroValue ? this._format(this.seconds) : this.seconds}${this.secondLabelValue}`
    }
  }

  _timeUp() {
    if (this.hasPastTimeTarget) {
      this.pastTimeTarget.classList.remove('hidden')
    }

    if (this.hasBeforeTimeTarget) {
      this.beforeTimeTarget.classList.add('hidden')
    }
  }

  _format(t) {
    return t < 10 ? '0' + t : t
  }

  _setTimeRemaining() {
    const currentTime = new Date().getTime()
    return new Date( this.endDateValue ).getTime() - currentTime
  }

  get days() {
    return Math.floor(this.timeRemaining / 1000 / 60 / 60 / 24)
  }

  get hours() {
    return Math.floor(this.timeRemaining / 1000 / 60 / 60) % 24
  }

  get minutes() {
    return Math.floor(this.timeRemaining / 1000 / 60) % 60
  }

  get seconds() {
    return Math.floor(this.timeRemaining / 1000) % 60
  }
}
