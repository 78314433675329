import { Controller } from "stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
	static targets = ["controlsTemplate", "progress", "controls", "track", "item"]
	static classes = ["bullet", "bulletActive", "bulletInactive"]

	connect() {
		if (!this.hasControlsTarget) this.#addControls()
		if (this.shouldHaveProgress) this.#setupProgress()

		// now we setup an intersection observer whenever the slider changes
		this.itemTargets.forEach((item) => useIntersection(this, { element: item, root: this.trackTarget, threshold: 0.9 }))
	}

	disconnect() {
		if (this.hasControlsTarget) this.controlsTarget.remove()
		if (this.hasProgressTarget) this.progressTarget.innerHTML = ""
	}

	appear(entry) {
		if (this.shouldHaveProgress) this.#updateBullets(entry.target)
	}

	previous() {
		const currentIndex = this.itemTargets.findIndex((element) => element === this.currentItem)
		const target = currentIndex === 0 ? this.itemTargets[this.itemTargets.length - 1] : this.itemTargets[currentIndex - 1]
		this.trackTarget.scrollTo({ left: target.offsetLeft, behavior: this.scrollBehavior })
	}

	next() {
		const currentIndex = this.itemTargets.findIndex((element) => element === this.currentItem)
		const target = currentIndex === this.itemTargets.length - 1 ? this.itemTargets[0] : this.itemTargets[currentIndex + 1]
		this.trackTarget.scrollTo({ left: target.offsetLeft, behavior: this.scrollBehavior })
	}

	#addControls() {
		// we don't add any controls if we don't have more than 1 item available
		if (this.itemTargets.length < 2) return

		this.element.appendChild(this.controlsTemplateTarget.content.firstChild)
	}

	#setupProgress() {
		const current = this.currentItem

		this.itemTargets.forEach((item) => {
			const bullet = document.createElement("div")
			bullet.classList.add(...this.bulletClasses)
			bullet.classList.add(...(current === item ? this.bulletActiveClasses : this.bulletInactiveClasses))
			this.progressTarget.appendChild(bullet)
		})
	}

	#updateBullets(current) {
		const currentIndex = this.itemTargets.findIndex((element) => element === current)
		Array.from(this.progressTarget.children).forEach((element, index) => {
			if (currentIndex === index) {
				element.classList.remove(...this.bulletInactiveClasses)
				element.classList.add(...this.bulletActiveClasses)
			} else {
				element.classList.remove(...this.bulletActiveClasses)
				element.classList.add(...this.bulletInactiveClasses)
			}
		})
	}

	get currentItem() {
		return this.itemTargets[Math.ceil(this.trackTarget.scrollLeft / this.trackTarget.clientWidth)]
	}

	get shouldHaveProgress() {
		return this.hasProgressTarget && this.itemTargets.length > 1
	}

	get scrollBehavior() {
		return window.matchMedia("(prefers-reduced-motion: reduce)").matches ? "instant" : "smooth"
	}
}
