// Used when a form has two (or more) sections, but only want to submit
// a section when a radio is checked, and not others
// E.g. Given two radio buttons A and B, fieldsets C and D
// If radio A is checked, fieldset C is enabled and D is disabled
// If radio B is checked, fieldset C is disabled and D is enabled

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "choice", "fieldset" ]

  connect() {
  	this.disableFieldsets()
		this.choiceTargets.forEach(c => {
			c.addEventListener("change", () => { this.disableFieldsets() })
		})
  }

  disableFieldsets() {
  	this.choiceTargets.forEach(c => {
    	this.fieldsetTargets.find(f => f.id == c.dataset.fieldset).disabled = !c.checked
   })
  }
}
