// Enables Selectize on select fields

import { Controller } from "stimulus"
import ScriptLoader from "../utils/scriptloader"

export default class extends Controller {
	static values = {
		content: String,
		skidding: { type: Number, default: 0 },
		distance: { type: Number, default: 10 }
	}

	initialize() {
		this.destroy = this.destroy.bind(this)
	}

	connect() {
		ScriptLoader.loadScript('popper', () => {
			ScriptLoader.loadScript('tippy', () => {
				this.instance = tippy(this.element, {
					duration: 200,
					maxWidth: 300,
					allowHTML: true,
					content: this.contentValue,
					offset: [this.skiddingValue, this.distanceValue]
				});

				document.addEventListener('turbo:before-cache', this.destroy)
			})
		})
	}

	disconnect() {
		document.removeEventListener('turbo:before-cache', this.destroy)
	}

	destroy() {
		if (this.instance) {
			this.instance.destroy()
			this.instance = null
		}
	}
}
