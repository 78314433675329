import { Controller } from "stimulus"
import { diffInMinutes } from "../../utils/date"

export default class extends Controller {
	static targets = [ "fullscreen", "time" ]
	static values = { backoffTime: { type: Number, default: 10 } }

	fullscreenChanged() {
		if (document.fullscreenElement) {
			// we are on fullscreen to we need to setup the UI to work as expected when on fullscreen mode,
			// which means that we will basically just show events for a given time
			this.#setupFullscreen()
		} else {
			// we left full screen so we need to stop timers that could be working and then leave fullscreen
			// mode which means showing all events again
			this.#leaveFullscreen()
		}
	}

	goFullscreen() {
		if (!this.hasFullscreenTarget) return

		this.fullscreenTarget.requestFullscreen()
	}

	#setupFullscreen() {
		// first thing that we do when moving to fullscreen is hide all time targets, then we will
		// figure out which ones is the most appropriate to display
		this.timeTargets.forEach((element) => element.classList.add("hidden"))
		this.#selectEvents()
		this.scheduleInterval = setInterval(this.#selectEvents.bind(this), 60000)
	}

	#leaveFullscreen() {
		this.timeTargets.forEach((element) => element.classList.remove("hidden"))
		if (this.rotationInterval) clearInterval(this.rotationInterval)
		if (this.scheduleInterval) clearInterval(this.scheduleInterval)
	}

	#selectEvents() {
		let element = this.#findUpcoming()
		if (!element) element = this.#findNearest()
		// well ... if we still don't have any element ... we just don't do nothing
		if (!element) return

		this.timeTargets.forEach((time) => time.classList.add("hidden"))
		element.classList.remove("hidden")

		// we need to retrieve the events element to figure out if we need to setup an auto change
		// routine because we have too many items to display on screen
		const container = element.querySelector('[data-type="events"]')
		this.container = container
		if (this.rotationInterval) clearInterval(this.rotationInterval)
		if (container.scrollHeight > container.clientHeight) this.rotationInterval = setInterval(this.#rotate.bind(this), 30000)
	}

	#rotate() {
		const scrollPosition = this.container.scrollTop
		const scrollHeight = this.container.scrollHeight
		const clientHeight = this.container.clientHeight
		const newPosition = scrollPosition + clientHeight
		this.container.scrollTo({ top: newPosition < scrollHeight ? newPosition : 0, behavior: "smooth" })
	}

	#findUpcoming() {
		let upcoming = null
		const now = new Date()
		this.timeTargets.forEach((element) => {
			const time = new Date(element.dataset.time)
			const timeDifference = diffInMinutes(now, time)
			if (timeDifference <= this.backoffTimeValue) upcoming = element
		})

		return upcoming
	}

	#findNearest() {
		let nearest = null
		let currentDifference = null
		const now = new Date()
		this.timeTargets.forEach((element) => {
			const time = new Date(element.dataset.time)
			const timeDifference = diffInMinutes(now, time)

			if (!nearest || currentDifference > timeDifference) {
				nearest = element
				currentDifference = timeDifference
			}
		})

		return nearest
	}
}
