import { Controller } from "stimulus"
import { loadStripe, sessionSetup } from "../../utils/stripe"
import Rails from "@rails/ujs"

export default class extends Controller {
	static targets = [ "payment", "submit" ]
	static values = { setupUrl: String, amount: Number, currency: String, billingDetails: Object }

	connect() {
		if (this.element.tagName !== "FORM") {
			console.error("This controller must be attached to a form element")
			return
		}

		// load Stripe client and then setup customer session, this way we will be able to
		// access any previously saved card that the user can use to start subscription
		this.stripe = loadStripe()
		if (!this.stripe) return

		this.#setup()
	}

	async onSubmit(event) {
		event.preventDefault()
		event.stopImmediatePropagation()

		if (this.shouldValidate()) {
			const { error: submitError } = await this.elements.submit()
			if (submitError) {
				// NOTE: far from perfect solution, but for now we will leave it that way, basically we will
				// show an alert with the first error message, but the fields will all be highlighted signaling
				// the error for each field, so eventually we need to check if it makes sense to have a more generic
				// error message instead of displaying the elements error
				if (alertify) alertify.error(submitError.message)
				return
			}
		}

		Rails.disableElement(this.submitTarget)

		const response = await fetch(this.element.action, { method: "POST", body: new FormData(this.element) })
		const { type, client_secret: clientSecret, return_url: returnUrl, subscription_url: subscriptionUrl } = await response.json()

		if (!this.shouldValidate()) {
			Turbo.visit(subscriptionUrl)
			return
		}

		const confirmIntent = type === "setup" ? this.stripe.confirmSetup : this.stripe.confirmPayment
		const elements = this.elements
		const { error } = await confirmIntent({
			elements,
			clientSecret,
			redirect: "if_required",
			confirmParams: {
				payment_method_data: { billing_details: this.billingDetailsValue },
				return_url: returnUrl
			}
		})

		if (error) {
			Rails.enableElement(this.submitTarget)
			if (alertify) alertify.error(error.message)
		} else {
			Turbo.visit(subscriptionUrl)
		}
	}

	async #setup() {
		if (!this.shouldValidate()) return

		({ elements: this.elements, paymentElement: this.paymentElement } =
			await sessionSetup(this.setupUrlValue, this.stripe, this.paymentTarget, { mode: "subscription", amount: this.amountValue, currency: this.currencyValue }))
	}

	shouldValidate() {
		return window.App.environment !== "test"
	}
}
