import { Controller } from "stimulus"
import { useMatchMedia, useMutation } from "stimulus-use"

export default class extends Controller {
	static values = { roomsUrl: String }

	/**
	 * for now the only objective of these controller is to control the number of chat windows that
	 * are open at a given time, right now we have a strict value of 2 open windows at a time when
	 * a new one appears we remove the oldest from the list, in the future we can probably have something
	 * more elegant ... eventually check how much available space is available and allow to open the
	 * necessary number to occupy the available space
	 */
	connect() {
		useMutation(this, { childList: true })
		useMatchMedia(this, { mediaQueries: { mobile: "(max-width: 1023px)" } })

		// we need to check if we are trying to load the chat full page version, if that is true
		// we should remove the floating chat since is not needed here and will cause bugs if it stays
		if (window.location.pathname.startsWith(this.roomsUrlValue)) this.element.remove()
	}

	sliderOpened(event) {
		if (this.element.childNodes.length === 0) return

		const sliderElement = event.detail.slider
		this.element.style.transform = `translateX(-${sliderElement.clientWidth}px)`

		// OK, if we have more than one chat conversation open we will check if there's still space
		// available, if not we will just remove the oldest one
		if (this.element.childNodes.length > 1) {
			// HACK: the timeout is kind of sketchy, ideally we should make some measurements here, we
			// already know where the element will be so can check if there's space for 2 chat windows
			// or not
			setTimeout(() => {
				// we will not compare left with 0 but with 80 which is the width of the sidebar navigation
				// for now we will have it hard-coded directly here
				const clientRect = this.element.getBoundingClientRect()
				if (clientRect.left <= 80) this.element.childNodes[0].remove()
			}, 500)
		}
	}

	sliderClosed() {
		this.element.style.transform = "translateX(0)"
	}

	mutate(entries) {
		entries.forEach((entry) => {
			if (entry.addedNodes.length > 0) {
				// basically if we are adding a new node, we will want to check if we have reached the
				// limit of 2 open chats, if we have reached that number we will just remove the oldest
				// one ... which in this cases is the first in the list
				if (this.element.childNodes.length > 2) this.element.childNodes[0].remove()
			}
		})
	}

	isMobile() {
		// basically if we are on mobile we just want to clean everything that could be on the
		// element because the floating chats aren't available for mobile, later maybe we can think
		// on some way of restore the chats if we move to a desktop breakpoint, for now we are just
		// through the easiest path
		this.element.innerHTML = ""
	}
}
