import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {

  static component = "scan"

	onStartScan() {
		this.#notifyBridgeOfScan()
  }

	#notifyBridgeOfScan() {
    this.send("scan", {title: 'scan'}, (message) => {
			try {
				const url = new URL(message.data.code)
			
				Turbo.visit(url.href, { action: "replace" });
			} catch (e) {
				console.warn("Invalid URL in QR code:", message.data.code)
			}
    })
	}
}