import { Controller } from "stimulus"

export default class extends Controller {
	static values = { amount: Number, code: String, exponent: Number }

	connect() {
		this._format()
	}

	amountValueChanged() {
		this._format()
	}

	_format() {
		const formatter = new Intl.NumberFormat(
			navigator.language,
			{
				style: 'currency',
				currency: this.codeValue,
				maximumFractionDigits: this._maximumFractionDigits()
			}
		)
		this.element.innerText = formatter.format(this.amountValue)
	}

	_maximumFractionDigits() {
		if (this.exponentValue === 0) return 0

		return this.amountValue % 1 === 0 ? 0 : this.exponentValue
	}
}
