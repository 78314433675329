import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "message" ]
	static values = { delay: Number }

	initialize() {
		alertify.set("notifier", "position", "top-center")
		alertify.defaults.notifier.closeButton = true
		// we map the corresponding type between Rails flash messages and alertify types
		this.mapping = { alert: "error", notice: "notify" }
	}

	connect() {
		this.messageTargets.forEach((target) => {
			let type = target.dataset.type
			const mapped = this.mapping[type]
			if (mapped !== undefined) type = mapped

			alertify[type](target.innerText).delay(this.delayValue)
		})
	}

	disconnect() {
		// we don't need the element anymore, so we get rid of it
		this.element.remove()
	}
}
