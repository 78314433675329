import { Controller } from "stimulus"
import * as Utils from "../utils/common"

export default class extends Controller {
  static targets = [ "button", "stage" ]
  static values = { provider:String, embed:String }

  onPlay(event) {
    if (!this.hasStageTarget) return

    event.preventDefault()
    this.stageTarget.innerHTML = `<iframe src="${Utils.sourceForVideoProvider(this.providerValue, this.embedValue)}" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>`
    this.stageTarget.classList.add('player__canvas--playing')
  }
}
