import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
	connect() {
		this.dispatch("open")
		useClickOutside(this)
	}

	disconnect() {
		this.#close(false)
	}

	clickOutside(e) {
		if (this.#isTrigger(e.target)) return

		this.#close()
	}

	close() {
		this.#close()
	}

	#close(dispatchEvent = true) {
		if (dispatchEvent) this.dispatch("closed", { detail: this.element.parentNode.src })
		this.element.classList.remove(this.openedClass)
		this.element.classList.add(this.closedClass)
		this.element.addEventListener("transitionend", () => this.element.remove(), { once: true })
	}

	#isTrigger(button) {
		if (button.dataset.turboFrame !== "drawer") return false

		const frameUrl = new URL(this.frame.src)
		return frameUrl.pathname === button.pathname
	}

	get frame() {
		return this.element.closest("turbo-frame")
	}

	get openedClass() {
		return "translate-x-0"
	}

	get closedClass() {
		return "translate-x-full"
	}
}
