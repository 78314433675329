import { Controller } from "stimulus"
import { getUserPreferences } from "../utils/common"
import * as DateFn from "../utils/date"

export default class extends Controller {
	static targets = [ "output" ]
	static values = { date:String, friendlyLabel:Boolean }

	connect() {
		// you may wonder if this could be placed in `initialize` method and the answer is `no` because
		// for some elements like floating chat windows we are persisting the elements between navigations
		// if we do this in `initialize` we wouldn't be able to retrieve any hour format changes made
		// by the users, here we ensure to have always the right settings stored
		this.preferences = getUserPreferences()

		const date = new Date(this.date)
		if (this.friendlyLabelValue) {
			this.#friendlyFormat(date)
		} else {
			this.#format(date)
		}
	}

	/**
	 * Friendly formats a date, meaning that if we are in the same day we will show the time, if
	 * it was yesterday we will display `Yesterday`, in the previous week we show the week day name
	 * and finally we show date format
	 */
	#friendlyFormat(date) {
		if (DateFn.isToday(date)) {
			this.#format(date)
		} else if (DateFn.isYesterday(date)) {
			this.output.innerHTML = "Yesterday"
		} else if (DateFn.isLastWeek(date)) {
			this.#format(date, { weekday: "long" })
		} else if (DateFn.isSameYear(date)) {
			this.#format(date, { day: "numeric", month: "numeric" })
		} else {
			this.#format(date, { day: "numeric", month: "numeric", year: "numeric" })
		}
	}

	#format(date, options = {}) {
		options = Object.keys(options).length > 0 ? options : this.#optionsForFormatter()
		const formatter = new Intl.DateTimeFormat("en-US", options)
		this.output.innerHTML = formatter.format(date)
	}

	#optionsForFormatter() {
		return {
			hour: "numeric",
			minute: "numeric",
			hour12: this.preferences.hourFormat === "hour12",
			timeZone: this.preferences.timeZone
		}
	}

	/**
	 * Retrieves the date string to be used in conversion of the date, we will look for a `dateValue`,
	 * if it's not defined we will check if the output element is a `<time>` element and retrieve
	 * the `dateTime` field instead
	 */
	get date() {
		if (this.hasDateValue) return this.dateValue
		if (this.output.tagName === "TIME") return this.output.dateTime

		return null
	}

	/**
	 * Retrieves the output element, we will always look first if there's an output target
	 * defined, if it isn't defined we will fallback to the element itself
	 */
	get output() {
		if (this.hasOutputTarget) return this.outputTarget

		return this.element
	}
}
