import { Controller } from "stimulus"

export default class extends Controller {
	static classes = [ "active", "inactive" ]
	static values = { highlightsOn: Array }

	initialize() {
		this.isClicked = false
		this.onInteraction = this.#onInteraction.bind(this)
		this.onClick = this.#onClick.bind(this)
		this.onDrawerClose = this.#onDrawerClose.bind(this)
	}

	connect() {
		if (this.highlightsOnValue.length > 0) this.#checkForHighlighted()

		this.element.addEventListener("click", this.onClick)
		this.element.addEventListener("mouseover", this.onInteraction)
		this.element.addEventListener("focus", this.onInteraction)
		this.element.addEventListener("mouseout", this.onInteraction)
		this.element.addEventListener("blur", this.onInteraction)
		document.addEventListener("drawer--container:closed", this.onDrawerClose)
	}

	disconnect() {
		this.isClicked = false
		this.#setInactive()
		this.element.removeEventListener("click", this.onClick)
		this.element.removeEventListener("mouseover", this.onInteraction)
		this.element.removeEventListener("focus", this.onInteraction)
		this.element.removeEventListener("mouseout", this.onInteraction)
		this.element.removeEventListener("blur", this.onInteraction)
		document.removeEventListener("drawer--container:closed", this.onDrawerClose)
	}

	#onClick(e) {
		if (this.isClicked) e.preventDefault()

		this.isClicked = true
		this.#setActive()
	}

	#onDrawerClose(e) {
		const url = new URL(e.detail)

		if (this.element.pathname === url.pathname) {
			this.isClicked = false
			this.#setInactive()
		}
	}

	#onInteraction(e) {
		if (this.isClicked) return

		switch (e.type) {
			case "mouseover":
			case "focus":
				this.#setActive()
				break;
			default:
				this.#setInactive()
		}
	}

	#checkForHighlighted() {
		const match = this.highlightsOnValue.find((option) => location.pathname.startsWith(option))
		if (match !== undefined) {
			this.isClicked = true
			this.#setActive()
		}
	}

	#setActive() {
		this.element.classList.remove(...this.inactiveClasses)
		this.element.classList.add(...this.activeClasses)
	}

	#setInactive() {
		this.element.classList.add(...this.inactiveClasses)
		this.element.classList.remove(...this.activeClasses)
	}
}
