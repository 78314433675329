export function loadStripe() {
	if (!App.Credentials.Stripe) {
		console.error("Stripe key not set, exiting")
		return null
	}

	if (typeof window.Stripe === "undefined") {
		console.error("Stripe.js library not found")
		return null
	}

	return window.Stripe(App.Credentials.Stripe.key, { locale: "en", apiVersion: App.Credentials.Stripe.apiVersion })
}

export function initializePaymentElement(stripe, target, options = {}) {
	// we will need to retrieve computed styles from `body` so that we can access CSS variables
	// and then we are able to send them to the appearance of payment element
	const style = getComputedStyle(document.body)

	// font definition, for now we are just using 400 and 700 weights, also if in the future the
	// site font changes this needs to be updated accordingly
	const fonts = [
		{
			cssSrc: "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"
		}
	]

	// our basic settings in order to payment element resemble like the other elements in page,
	// using our styles, since we are styling inputs/labels here any future change in the general
	// look of inputs/labels will need to be replicated here
	const appearance =  {
		theme: "stripe",
		variables: {
			borderRadius: "6px",
			colorBackground: style.getPropertyValue("--thu-color-surface-2"),
			colorDanger: style.getPropertyValue("--thu-color-error"),
			colorPrimary: style.getPropertyValue("--thu-color-secondary"),
			colorText: style.getPropertyValue("--thu-color-text-primary"),
			colorTextPlaceholder: `rgba(${style.getPropertyValue("--thu-color-rgb-text-neutral")}, .3)`,
			colorTextSecondary: style.getPropertyValue("--thu-color-text-neutral"),
			fontFamily: "Montserrat, system-ui, sans-serif",
			fontLineHeight: "1.5"
		},
		rules: {
			".Input": {
				lineHeight: "var(--fontLineHeight)",
				padding: "6px 12px"
			},
			".Label": {
				fontSize: "0.75rem",
				fontWeight: "700",
				marginBottom: "6px",
				textTransform: "uppercase"
			},
			".AccordionItem": {
				border: "none"
			},
			".Error": {
				fontSize: "0.75rem",
				fontWeight: "700",
				marginTop: "8px",
				textTransform: "uppercase"
			}
		}
	}

	const elements = stripe.elements(Object.assign(options, { appearance: appearance, fonts: fonts }))
	// we will use the accordion layout and we will not request the country and postal code when asking
	// for credit cards
	const paymentElement = elements.create("payment", { layout: "accordion", fields: { billingDetails: "never" } })
	paymentElement.mount(target)
	return { elements, paymentElement }
}

export function initializeCardElement(stripe, target, options = {}) {
	// we need to tell Stripe to load Montserrat from Google Fonts
	const elements = stripe.elements({
		fonts: [ { cssSrc: "https://fonts.googleapis.com/css?family=Montserrat" } ]
	})

	// now we need to define default styles to card element
	const styles = {
		base: {
			color: "#fff",
			fontFamily: "Montserrat, sans-serif",
			fontSize: "14px",
			fontWeight: "normal",
			fontSmoothing: "antialised",
			lineHeight: "20px",
			'::placeholder': {
				color: "#8a8a9f"
			}
		},
		invalid: {
			color: "#e75557",
			iconColor: "#e75557"
		}
	}

	const field = elements.create("card", Object.assign(options, { style: styles, hidePostalCode: true }))
	field.mount(target)
	return field
}

export async function sessionSetup(setupUrl, stripe, target, options = {}) {
	// we need to retrieve the CSRF token so that we can send it in the request, otherwise an authenticity
	// token error will get thrown
	const token = document.querySelector('meta[name="csrf-token"]').content
	const sessionSetup = await fetch(setupUrl, { method: "POST", headers: { "X-CSRF-Token": token } })
	const response = await sessionSetup.json()
	// we need to check and set the secrets send by the setup request, we need to do a check because
	// for some scenarios we only need one or the other (probably this will only happen for `clientSecret`)
	if (response.client_secret) options.clientSecret = response.client_secret
	if (response.customer_session_client_secret) options.customerSessionClientSecret = response.customer_session_client_secret

	const initializer = initializePaymentElement(stripe, target, options)
	return { elements: initializer.elements, paymentElement: initializer.paymentElement }
}
