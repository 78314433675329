import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["field", "option"]

	connect() {
		if (this.optionTargets.length > 2) {
			console.error("Only use this controller when working with boolean like inputs.")
			return
		}

		const checked = this.optionTargets.find((option) => option.checked)
		if (checked) this.#toggleField(checked)
	}

  toggleField(event) {
		this.#toggleField(event.target)
  }

	#toggleField(input) {
		if(this.#shouldToggleFor(input)) {
			this.fieldTarget.classList.remove(this.disableClass)
			this.fieldTarget.disabled = false
			this.fieldTarget.focus()
		} else {
			this.fieldTarget.classList.add(this.disableClass)
			this.fieldTarget.disabled = true
			// basically we will allow other elements here, so it's not an input field per se, so we
			// check if the target have a value property and only set to empty the value if the field exists
			if (this.fieldTarget.value !== undefined) this.fieldTarget.value = ""
		}
	}

	#shouldToggleFor(input) {
		const value = input.value;
		return value === 'user' || value === '1' || value === true || value === 'true';
	}

	get disableClass() {
		return "disabled"
	}
}
