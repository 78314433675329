import { Controller } from "stimulus"

export default class extends Controller {
  doCollapse(event) {
    const button = event.currentTarget
    event.preventDefault()
    const url = new URL(button.href)
    const target = document.querySelector(url.hash)

    if (target) {
      target.classList.toggle('show')
      button.setAttribute('aria-expanded', target.classList.contains('show'))
    }
  }
}
