import { Controller } from "stimulus"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class extends Controller {

	initialize() {
		gsap.registerPlugin(ScrollTrigger);

		gsap.to('.eligible-content', {
			filter: "blur(4px)",
			scrollTrigger: {
				trigger: ".eligible-trigger",
				start: "200 top",
				end: "+=100",
				scrub: true
			}
		})

		gsap.to('.eligible', {
			scrollTrigger: {
				trigger: ".eligible",
				start: "top top",
				end: "120% top",
				pin: true,
				scrub: true
			}
		})

		let floatingImages = document.querySelectorAll(".floating-image");
		floatingImages.forEach(function (sec, i) {
			gsap.to(sec, {
				y: -2000,
				scrollTrigger: {
					trigger: ".eligible-trigger",
					start: 150 + (100 * i) + " top",
					end: "+=900",
					scrub: 2,
					toggleActions: 'play none none reverse',
				}
			})
		})

	}

}
