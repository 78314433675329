import { Controller } from "stimulus"
import ScriptLoader from "../utils/scriptloader"

export default class extends Controller {
	static targets = [ 'wrapper', 'previous', 'next' ]

	initialize() {
		this.destroy = this._destroy.bind(this)
	}

	connect() {
		ScriptLoader.loadScript('glider', () => {
			const self = this

			this.wrapperTarget.addEventListener('glider-loaded', function(event){
				const glider = Glider(this)
				const activeSlide = self.wrapperTarget.querySelector('.is-active')
				const parent = activeSlide.parentNode

				glider.opt.duration = 0

				glider.scrollItem( Array.prototype.indexOf.call(parent.children, activeSlide) )

				self.wrapperTarget.classList.remove('opacity-0')

				self.updateControls(glider)
			})

			this.wrapperTarget.addEventListener('glider-animated', function(event){
				const glider = Glider(this)

				glider.opt.duration = 0.5
			})

			this.wrapperTarget.addEventListener('glider-refresh', function(event){
				const glider = Glider(this)

				self.updateControls(glider)
			})

			this.instance = new Glider( this.wrapperTarget, {
				slidesToShow: 3,
				slidesToScroll: 3,
				draggable: true,
				arrows: {
					prev: self.previousTarget,
					next: self.nextTarget
				},
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 4,
							slidesToShow: 4
						}
					},{
						breakpoint: 1024,
						settings: {
							slidesToShow: 7,
							slidesToScroll: 7
						}
					}
				]
			})

			document.addEventListener('turbo:before-cache', this.destroy)
		})
	}

	disconnect() {
		document.removeEventListener('turbo:before-cache', this.destroy)
	}

	_destroy() {
		if(this.instance) {
			this.instance.destroy()
			this.instance = null
		}
	}

	updateControls(glider) {
		if( glider.slides.length > glider.opt.slidesToShow ) {
			this.wrapperTarget.classList.remove('justify-center')
			this.nextTarget.classList.remove('invisible')
			this.previousTarget.classList.remove('invisible')
		} else {
			this.wrapperTarget.classList.add('justify-center')
			this.nextTarget.classList.add('invisible')
			this.previousTarget.classList.add('invisible')
		}
	}
}
