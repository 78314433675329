import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "select" ]
	static values = { input: String, original: String }

	connect() {
		// because we need to have the input element value defined otherwise there's no point
		// in do anything
		if (!this.hasInputValue) return

		// now we try to find the element defined by the input value, if we don't find any
		// we are out again, without this element there's no need to continue
		this.input = document.querySelector(this.inputValue)

		// because browsers in soft refreshes can store the change field we are checking if the
		// select current value matches the original value and if not we trigger the city selector update
		if (this.selectTarget.value !== this.originalValue) this.#updateCitySelector(this.selectTarget.value)
	}

	onCountryChange(event) {
		if (!this.input) return

		// we will update the city input with a URL that will allow to filter cities only for
		// the given country
		this.#updateCitySelector(event.target.value)
	}

	#updateCitySelector(countryCode) {
		const location = window.location
		const url = new URL(this.input.dataset.selectizeRemoteValue, `${location.protocol}//${location.host}`)
		url.searchParams.set("code", countryCode)
		this.input.innerHTML = ""
		this.input.toggleAttribute("deselectize", countryCode === "")
		this.input.dataset.selectizeRemoteValue = url.toString()
	}
}
