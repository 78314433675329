import { Controller } from "stimulus"

export default class extends Controller {
	initialize() {
		this.handleResize = this._handleResize.bind(this)
	}

	connect() {
		window.addEventListener('resize', this.handleResize)
		this._handleResize()
	}

	disconnect() {
		window.removeEventListener('resize', this.handleResize)
	}

	_handleResize() {
		if ( this.element.scrollWidth > window.innerWidth ) {
			this.element.classList.add('is-overflowing')
		} else {
			this.element.classList.remove('is-overflowing')
		}
	}
}
