import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "leftPaddle", "rightPaddle", "wrapper", "container", "item" ]
  static values = {
    dynamicBackground: Boolean
  }

  initialize() {
    // get items dimensions
    this.itemsLength = this.itemTargets.length;
    this.itemSize = this.itemTarget.offsetWidth;

    this.onLeftPaddle = this._onLeftPaddle.bind(this)
    this.onRightPaddle = this._onRightPaddle.bind(this)
  }

  connect() {
    this.menuWrapperSize = this.wrapperTarget.offsetWidth;
    this.menuSize = this.getMenuSize();
    this.menuInvisibleSize = this.menuSize - this.menuWrapperSize;

    if( this.hasLeftPaddleTarget ) {
      this.leftPaddleTarget.addEventListener('click', this.onLeftPaddle)
    }

    if( this.hasRightPaddleTarget ) {
      this.rightPaddleTarget.addEventListener('click', this.onRightPaddle)
    }

    window.addEventListener('resize', () => {
      this.menuWrapperSize = this.wrapperTarget.offsetWidth;
    });

    if (this.dynamicBackgroundValue) {

      let itemOffset = this.itemSize / 4;
      let currentBg = 0;

      this.containerTarget.addEventListener('scroll', (e) => {

        if (currentBg != Math.round((e.currentTarget.scrollLeft + itemOffset) / this.itemSize)) {
          currentBg = Math.round((e.currentTarget.scrollLeft + itemOffset) / this.itemSize);
          this.changeBackground(currentBg);
        }
      });
    }
  }

  changeBackground(item) {
    let c = this.containerTarget.querySelectorAll("article")[item].dataset.bgItem;
    $('#thu-universe-wrapper').removeClass();
    $("#thu-universe-wrapper").addClass(c);

  }

  getMenuSize = function() {
    return this.itemsLength * this.itemSize;
  };

  _onLeftPaddle() {
    if (this.containerTarget.scrollLeft > 0) {
      this.containerTarget.scrollBy({
        left: -this.menuWrapperSize,
        behavior : "smooth"
      })
    }
  }

  _onRightPaddle() {
    if (this.containerTarget.scrollLeft < this.menuInvisibleSize) {
      this.containerTarget.scrollBy({
        left: this.menuWrapperSize,
        behavior : "smooth"
      })
    }
  }

}
