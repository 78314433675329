import { Controller } from "stimulus"

export default class extends Controller {
	static values = { content:String, textColor: { type: String, default: "#000000" } }

	connect() {
		if (this.element.children.length > 0) this.element.innerHTML = ""
		const target = this.element
		const svg = QRCode.generateSVG(this.contentValue, { ecclevel: "H", fillcolor: "transparent", textcolor: this.textColorValue })
		target.appendChild(svg)
	}

	disconnect() {
		this.element.innerHTML = ""
	}
}
