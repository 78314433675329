import { Controller } from "stimulus"

export default class extends Controller {
	static values = { regex: String }

  checkCharacters(event) {
		const regex = new RegExp(this.regexValue);

		if (event.data != null && !regex.test(event.data)) {
    	event.preventDefault();
		}
  }
}
