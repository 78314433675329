export function createTimeElement(date, classes) {
	classes = classes || ["time-separator", "thu-color-text-neutral", "text-center", "text-md"]
	const today = new Date()
	const element = document.createElement("time")
	element.dateTime = date.toISOString().slice(0, 10)
	element.classList.add(...classes)
	const options = { day: "numeric", month: "short" }
	if (today.getFullYear() !== date.getFullYear()) options.year = "numeric"
	element.innerText = new Intl.DateTimeFormat("en-US", options).format(date)
	return element
}
