import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = ['container']

  connect() {
		this.inputs = Array.from(
			this.containerTarget.querySelectorAll('input[required]')
		)

		this.removeRequired()
	}

	removeRequired() {
		this.inputs
			.forEach(i => (i.required = false));
	}

	addRequired() {
		this.inputs
			.forEach(i => (i.required = true));
	}
}
