import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "fields", "autofocus" ]

	started() {
		// now we check if there's an autofocus target, and we will only retrieve the first because
		// we can just autofocus one element
		if (this.hasAutofocusTarget) this.autofocusTarget.focus()
	}

	clear() {
		// we will do a clear if we have any fields defined, otherwise we will check if
		// we are dealing with a form and if that's true we clear the fields
		if (this.hasFieldsTarget) {
			this.fieldsTargets.forEach((field) => {
				if (field.type === "checkbox" || field.type === "radio") {
					field.checked = false
				} else {
					field.value = null
				}
			})
		} else if (this.element.nodeName === "FORM") {
			this.element.reset()
		}
	}
}
