import { Controller } from 'stimulus'

export default class extends Controller {
	static targets = ['step', 'section', 'continue', 'back', 'submit']

	connect() {
		this.activeStepClass = 'active'
		this.inactiveStepClass = 'is-inactive'
		this.inactiveContinueClass = 'is-hidden'
		this.nextSectionIndex = 0

		this.index = this.stepTargets.findIndex(step => step.ariaSelected)
		if (this.anchor) {
			this.index = this.stepTargets.findIndex((step) => step.id === this.anchor)
		}

		this.showStep()
	}

	change(event) {
		event.preventDefault()
		const { index, id } = event.currentTarget.dataset

		if (index) {
			this.nextSectionIndex = parseInt(index, 10);
		} else if (id) {
			this.nextSectionIndex = this.stepTargets.findIndex(step => step.id === id);
		} else {
			this.nextSectionIndex = this.stepTargets.indexOf(event.currentTarget);
		}

		// Moving to a higher index, validate the current step first
		if (this.nextSectionIndex > this.index && this.valid) {
			this.index = this.nextSectionIndex

		// Moving to a lower or same index, no validation needed
		} else if (this.nextSectionIndex <= this.index) {
			this.index = this.nextSectionIndex
		}
	}

	advance() {
		if (this.index + 1 < this.stepTargets.length) {
			this.nextSectionIndex = this.index + 1

			// Only change if no invalid fields in current and in-between steps
			if (this.valid) {
				this.index = this.nextSectionIndex
			}
		}
	}

	recede() {
		if (this.index > 0) {
			this.nextSectionIndex = this.index - 1
			this.index = this.nextSectionIndex
		}
	}

	showStep() {
		// Show the current step and update the visibility of other steps
		this.stepTargets.forEach((step, index) => {
			const section = this.sectionTargets[index]
			const isActive = index === this.index

			section.classList.toggle('hidden', !isActive)
			step.classList.toggle(this.inactiveStepClass, !isActive)
			step.classList.toggle(this.activeStepClass, isActive)
			step.ariaSelected = isActive.toString()
		})

		this.updateControls()
		this.clearAnchor()
		this.scrollToTop()
	}

	updateControls() {
		const isLastStep = this.index + 1 === this.stepTargets.length
		const isFirstStep = this.index === 0

		if (this.hasContinueTarget) this.toggleElement(this.continueTarget, !isLastStep)
		if (this.hasSubmitTarget) this.submitTargets.forEach(st => this.toggleElement(st, isLastStep))
		if (this.hasBackTarget) this.toggleElement(this.backTarget, !isFirstStep)
	}

	toggleElement(element, show) {
		if (element) {
			element.classList.toggle('is-hidden', !show)
		}
	}

	clearAnchor() {
		if (this.anchor) {
			history.pushState("", document.title, window.location.pathname + window.location.search)
		}
	}

	scrollToTop() {
		const scrollParent = this._scrollParent(this.element)
		if (scrollParent) {
			scrollParent.scrollTop = 0
		}
	}

	get index() {
		return parseInt(this.data.get('index') || 0, 10)
	}

	set index(value) {
		this.data.set('index', Math.max(0, value))
		this.showStep()
	}

	get anchor() {
		const hash = document.URL.split('#')[1]
		return hash || null
	}

	get valid() {
		// Check if all sections up to the nextSectionIndex are valid
		for (let i = 0; i < this.nextSectionIndex; i++) {
			const section = this.sectionTargets[i]

			for (const input of section.querySelectorAll('input, select, textarea')) {
				if (!input.checkValidity()) {
					this.showError(input, i)
					return false
				}
			}
		}

		return true
	}

	showError(input, sectionIndex) {
		// Show error for an invalid input and set the current step to the section with the error
		const parentDropdown = input.closest('[data-controller="dropdown"]')

		if (parentDropdown && parentDropdown.getAttribute('aria-expanded') === 'false') {
			const dropdownButton = parentDropdown.querySelector('[data-dropdown-target="button"]')
			if (dropdownButton) {
				dropdownButton.click()
			}
		}

		this.index = sectionIndex

		setTimeout(() => {
			input.reportValidity()
		}, 300)
	}

	_scrollParent(node) {
		if (!node) return null
		if (node.scrollHeight > node.clientHeight) return node
		return this._scrollParent(node.parentNode)
	}
}
