import { Controller } from "stimulus"
import { useMatchMedia } from "stimulus-use"

export default class extends Controller {
	static values = { docked: Boolean }

	initialize() {
		this.cachedNodes = []
	}

	connect() {
		// basically if we don't have the current selector element in the dock (or drawer) we want
		// to control if we show them or not based on a media query
		if (!this.dockedValue) useMatchMedia(this, { mediaQueries: { mobile: "(max-width: 1023px)" } })
	}

	isMobile() {
		// if we are on mobile we just remove all the children elements from the screen and
		// cache them in the controller, then if we move to a desktop-like device we will re-attach
		// the elements and things should automatically update
		this.element.childNodes.forEach((node) => {
			this.cachedNodes.push(node.cloneNode(true))
			node.remove()
		})
		this.element.classList.add("hidden")
	}

	notMobile() {
		// OK, if we leave the mobile breakpoint, we need to loop through the cached nodes and append
		// them to the element, additionally if the node is a `<turbo-frame>` (which it should be) we
		// will reload the frame to get any updated information that could have popped
		this.cachedNodes.forEach((node) => {
			this.element.appendChild(node)
			if (node.nodeName === "TURBO-FRAME") node.reload()
		})
		// then we clear the cached nodes and how the element again
		this.cachedNodes = []
		this.element.classList.remove("hidden")
	}
}
