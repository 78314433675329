import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['item', 'show', 'select', 'cancel', 'toolbar', 'action', 'selectCount', 'selectAll', 'toggler']

  connect() {
		this.visibilityClass = 'hidden'
		this.disabilityClass = 'is-disabled' // Ugh...

		this.selectedLabel = 'selected'
	}

	show() {
		this.itemTargets.forEach((item, index) => {
				item.querySelector('.bulk-actions-checkbox').classList.remove( this.visibilityClass )
		})

		if ( this.hasShowTarget ) {
			this.showTarget.classList.add( this.visibilityClass )
		}

		if ( this.hasSelectTarget ) {
			this.selectTarget.classList.remove( this.visibilityClass )
		}

		if ( this.cancelTargets.length > 0 ) {
			this.cancelTargets.forEach((item) => {
				item.classList.remove( this.visibilityClass )
			})
		}

		if ( this.hasToolbarTarget ) {
			this.toolbarTarget.classList.remove( this.visibilityClass )
		}
	}

	hide() {
		this.itemTargets.forEach((item, index) => {
				item.querySelector('.bulk-actions-checkbox').classList.add( this.visibilityClass )
		})

		if ( this.hasShowTarget ) {
			this.showTarget.classList.remove( this.visibilityClass )
		}

		if ( this.hasSelectTarget ) {
			this.selectTarget.classList.add( this.visibilityClass )
		}

		if ( this.cancelTargets.length > 0 ) {
			this.cancelTargets.forEach((item) => {
				item.classList.add( this.visibilityClass )
			})
		}

		if ( this.hasToolbarTarget ) {
			this.toolbarTarget.classList.add( this.visibilityClass )
		}
	}

	checkSelected() {
		if ( this.hasSelectCountTarget ) {
			this.selectCountTarget.innerHTML = this.checkedItemsCount + ' ' + this.selectedLabel
		}

		if ( this.checkedItemsCount > 0 ) {
			if ( this.actionTargets.length > 0 ) {
				this.actionTargets.forEach((item) => {
					item.classList.remove( this.disabilityClass )
				})
			}

			if ( this.hasSelectCountTarget ) {
				this.selectCountTarget.classList.remove( this.visibilityClass )
			}

			if ( this.hasSelectAllTarget ) {
				this.selectAllTarget.classList.add( this.visibilityClass )
			}

			if ( this.allChecked ) {
				if ( this.hasTogglerTarget ) {
					this.togglerTarget.indeterminate = false
					this.togglerTarget.checked = true
				}
			} else {
				if ( this.hasTogglerTarget ) {
					this.togglerTarget.indeterminate = true
					this.togglerTarget.checked = false
				}
			}
		} else {
			if ( this.actionTargets.length > 0 ) {
				this.actionTargets.forEach((item) => {
					item.classList.add( this.disabilityClass )
				})
			}

			if ( this.hasTogglerTarget ) {
				this.togglerTarget.indeterminate = false
				this.togglerTarget.checked = false
			}

			if ( this.hasSelectCountTarget ) {
				this.selectCountTarget.classList.add( this.visibilityClass )
			}

			if ( this.hasSelectAllTarget ) {
				this.selectAllTarget.classList.remove( this.visibilityClass )
			}
		}
	}

	toggleSelected(e) {
		this.itemTargets.forEach((item, index) => {
			item.querySelector('.bulk-actions-checkbox input[type="checkbox"]').checked = e.target.checked
		})

		// Manually dispatch event, so we can catch programatically checked checkboxes
		this.element.dispatchEvent(new Event('change'))
	}

	selectSection(e) {
		var section = this.element.querySelector('#' + e.target.dataset.section)

		section.querySelectorAll("[data-bulk-actions-target='item'] .bulk-actions-checkbox input[type='checkbox']").forEach((item) => {
			item.checked = true
		})

		// Manually dispatch event, so we can catch programatically checked checkboxes
		this.element.dispatchEvent(new Event('change'))

		this.checkSelected()
	}

	get checkedItemsCount() {
		return this.element.querySelectorAll('.bulk-actions-checkbox input[type="checkbox"]:checked').length
	}

	get allChecked() {
		return this.checkedItemsCount == this.itemTargets.length
	}
}
