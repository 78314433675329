import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "option", "view" ]

	connect() {
		// we only show something if we have any option available
		if (this.optionTargets.length > 0) this.#showViews()
	}

	onChange() {
		this.#showViews()
	}

	#showViews() {
		this.viewTargets.forEach((v) => v.hidden = v.dataset.option !== this.selected.value)
	}

	get selected() {
		return this.optionTargets.find((o) => o.checked)
	}
}
