import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
	static values = { delay:Number, checkUrl:String, amount:Number, currency:String }

	initialize() {
		this.checks = 0
		// the maximum number of checks done, before reloading the page, we will try for 50 times,
		// after that we reload the page
		this.maxChecks = 50
	}

	connect() {
		if (this.checkUrl === "") {
			console.error("No check URL provided, so nothing will be done.")
			return
		}

		this.#doCheck()
	}

	#doCheck() {
		Rails.ajax({
			type: "get",
			url: this.checkUrlValue,
			success: (payment) => {
				if (payment.status === "pending") {
					this.checks += 1
					if (this.checks === this.maxChecks) {
						location.reload()
					} else {
						setTimeout(this.#doCheck.bind(this), Math.ceil(Math.random() * 5) * 1000)
					}
				} else {
					if (typeof fbq !== "undefined") fbq("track", "Purchase", { currency: this.currencyValue, value: this.amountValue })
					Turbo.visit(payment.feedback_url)
				}
			}
		})
	}
}
