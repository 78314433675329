import { Controller } from "stimulus"
import { useMeta, useMutation } from "stimulus-use"
import * as DOM from "../../utils/dom"
import { getUserPreferences } from "../../utils/common"

export default class extends Controller {
	static targets = [ "header", "thread", "messages", "autofocus" ]
	static values = { seenUrl: String }
	static metaNames = [ "csrf-token" ]

	initialize() {
		this.minimized = false
	}

	connect() {
		// we don't want to place this on `initialize` because if the user changes their settings we
		// want to have this updated with any setting change made by user
		this.preferences = getUserPreferences()

		// we just want to focus the autofocus target if it's specified
		if (this.hasAutofocusTarget) this.autofocusTarget.focus()

		// we we will meta so that we can retrieve the CSRF token from HTML, since we will need
		// them for later requests, we need to check if we don't have already defined the metas object
		// and only if not we will define `useMeta` otherwise redeclaration errors will pop out
		if (this.metas === undefined) useMeta(this)

		// we will just receive a list of messages but we want to have a date separator between
		// them, and to avoid have some time elements created in the backend and others in the
		// frontend we will opt by creating all of them in the frontend
		this.messagesTarget.querySelectorAll("[data-message]").forEach((message) => this.#dateSeparatorCheck(message))

		// we want to move to the bottom of the list because it will be there that will be
		// the most recent messages
		this.#moveToBottom()

		// we send seen request to server only if the chat is not minimized
		if (!this.minimized) this.#markAsSeen()

		// we want to observe the messages list so that we can create date separators if new days
		// pop in a given request
		useMutation(this, { element: this.messagesTarget, childList: true })
	}

	disconnect() {
		// on disconnect we clean ourselves up ... meaning ... we will remove any time tag
		// that we previously added
		this.messagesTarget.querySelectorAll("time").forEach((element) => element.remove())
	}

	submit(event) {
		event.preventDefault()
		const form = event.target.closest("form")
		// NOTE: OK, we can't just trust in `checkValidity` because if we insert empty lines ... that
		// makes the textarea valid ...
		const textarea = form.querySelector("textarea")
		if (textarea.value.trim() !== "") form.requestSubmit()
	}

	toggle() {
		this.minimized = !this.minimized
		let translateY = 0
		if (this.minimized) translateY = `calc(100% - ${this.headerTarget.clientHeight}px)`
		this.element.style.setProperty('--tw-translate-y', translateY)

		if (!this.minimized) this.#markAsSeen()
	}

	close() {
		this.element.remove()
	}

	paginate() {
		const firstMessageElement = this.messagesTarget.querySelector("[data-message]")
		this.threadTarget.scrollTo({ top: firstMessageElement.offsetTop, behavior: "smooth" })
	}

	mutate(entries) {
		// basically we will loop through the entries and check if we are dealing with a message
		// element, which means that we need to check if we have addedNodes and any of them is
		// a message, then we check the message to see if it's the last one, if that's true we will
		// check if we are more than half way of the scroll because we only scroll to bottom if
		// we are not more than half way the scroll
		entries.forEach((entry) => {
			if (entry.type !== "childList") return
			if (entry.addedNodes.length === 0) return

			entry.addedNodes.forEach((node) => {
				if (node.nodeName !== "DIV") return
				if (node.dataset.message !== "") return

				// we need to check if a date separator is needed and decide where to move the
				// element or if not need to move it at all
				this.#dateSeparatorCheck(node)

				if (node.dataset.fresh === "") {
					// NOTE: for now we will just move to bottom, but eventually this approach is kind of
					// naive later probably we will need to make this logic take some environmental factors
					// and decide if it needs to move to bottom or not
					this.#moveToBottom()
					// NOTE: in the same line as above, probably we also need to have a more sophisticated
					// approach to when to trigger the `#markAsSeen` method, for now it's always when a
					// new message arrives
					if (!this.minimized) this.#markAsSeen()
					// we need to check if there's an empty element and remove it if needed
					this.#removeEmptyElement()
				}
			})
		})
	}

	#removeEmptyElement() {
		const emptyElement = this.element.querySelector("[data-empty]")
		if (emptyElement) emptyElement.remove()
	}

	#markAsSeen() {
		if (!this.hasSeenUrlValue) return

		// for now we just make the request to the server, no need to know the answer, in the
		// future we will check want more needs to be done
		fetch(this.seenUrlValue, { method: "POST", headers: { "X-CSRF-Token": this.csrfTokenMeta } })
	}

	#moveToBottom() {
		this.threadTarget.scrollTop = this.threadTarget.scrollHeight - this.threadTarget.clientHeight
	}

	#dateSeparatorCheck(message) {
		const date = this.#toUserTimeZone(new Date(message.dataset.timeFormatDateValue))

		let timeElement = this.messagesTarget.querySelector(`time[datetime="${date.toISOString().slice(0, 10)}"]`)
		if (!timeElement) {
			timeElement = DOM.createTimeElement(date)
			message.parentNode.insertBefore(timeElement, message)
		} else {
			// here we need to check if we really need to move the element, basically for real-time
			// messages that are append we should not need to move them, but if we are going back
			// we need to move them after the time element
			const nextMessage = timeElement.nextSibling
			const nextDate = this.#toUserTimeZone(new Date(nextMessage.dataset.timeFormatDateValue))
			if (nextDate > date) message.before(timeElement)
		}
	}

	#toUserTimeZone(date) {
		return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: this.preferences.timeZone }))
	}
}
