import { Controller } from "stimulus"
import { useHover } from "stimulus-use"
import { Turbo } from "@hotwired/turbo-rails"
export default class extends Controller {
	static targets = [ "event" ]
	static classes = [ "selected" ]

	initialize() {
		this.intervalID = null
	}

	connect() {
		this.#happeningNow()
		useHover(this, { element: this.element })

		// we will setup an interval that will check each 5 minutes which event is selected (if any)
		// if we are in the last element we will use `Turbo` to visit the page again and eventually
		// get an updated schedule
		this.intervalID = setInterval(() => {
			const event = this.#currentEvent()
			if (event && this.eventTargets.indexOf(event) === 3) Turbo.visit(window.location.href)
		}, 300000)
	}

	disconnect() {
		if (this.intervalID) clearInterval(this.intervalID)
	}

	mouseEnter() {
		this.#removeSelected()
	}

	mouseLeave() {
		this.#happeningNow()
	}

	#happeningNow() {
		const event = this.#currentEvent()

		// if we have an event we highlight it
		if (event) event.classList.add(...this.selectedClasses)
	}

	#currentEvent() {
		const now = new Date()
		const event = this.eventTargets.find((element) => {
			const startTime = new Date(Date.parse(element.dataset.startTime))
			const endTime = new Date(Date.parse(element.dataset.endTime))
			return now >= startTime && now <= endTime
		})

		return event
	}

	#removeSelected() {
		this.eventTargets.forEach((element) => element.classList.remove(...this.selectedClasses))
	}

	// NOTE: this is just a hack because from Stimulus 3, we already have that option and we don't
	// need to do this, but we have a big jump from Stimulus 2 and all that could depend on that
	get selectedClasses() {
		return this.selectedClass.split(" ")
	}
}
