import { Controller } from "stimulus"
import { gsap } from "gsap"
import TweenLite from "gsap"
import TweenMax from "gsap"
import Draggable from "gsap/Draggable"

export default class extends Controller {
  static targets = [ "draggableEl", "draggableSection" ]
  static values = { itemHeight: Number, columns: Number }

  initialize() {

    gsap.registerPlugin(Draggable);

    this.animateToOrder = this._animateToOrder.bind(this)

    this.draggableSectionTarget.style.height = Math.round(this.draggableElTargets.length / this.columnsValue) * this.itemHeightValue + "px"

    var context = this;

    this.draggableElTargets.forEach( item =>  {
      item.style.height = this.itemHeightValue + 'px'
      this.setGridPos(item, item.dataset.order)

      Draggable.create(item, {
        type: "top,left",
        trigger: item.querySelector(".drag-trigger"),
        bounds: item.querySelector('.section-inner'),
        edgeResistance:0.5,
        onDrag: function(e) {
          let i = context.draggableElTargets.length
          while (--i > -1) {
            if (!TweenMax.isTweening(context.draggableElTargets) && this.hitTest(context.draggableElTargets[i], "50%")) {
              context.updateOrder(e.target.closest('.draggable-item'), context.draggableElTargets[i]);
            }
          }
        },
        onDragEnd: function(e) {
          var el = e.target.closest('.draggable-item')
          this.animateToOrder(el, el.dataset.order);
        }.bind(this)
      });
    });
  }

  getPos(order) {
    var pos_left = (order % this.columnsValue) * "50" ,
        pos_top = Math.floor((order/this.columnsValue) - 1) * this.itemHeightValue;
    return {'top': pos_top, 'left': pos_left };
  }

  updateOrder(active, target) {


    var active_order = active.dataset.order,
        target_order = target.dataset.order,
        i = this.draggableElTargets.length;



    // if the active box is moving up, animate others down
    if (active_order > target_order) {
      // loop through boxes
      while (--i > -1) {

        var el = this.draggableElTargets[i],
            order = parseInt(el.dataset.order, 10);

        if(order < active_order && order >= target_order) {
          el.dataset.order = order + 1;
          el.querySelector('.item-position').value = el.dataset.order;
          this.animateToOrder(el, order + 1);
        }
      }
    }
    // else if the active box is moving down, animate others up
    else {
      while (--i > -1) {
        var el = this.draggableElTargets[i],
            order = parseInt(el.dataset.order, 10);
        if(order > active_order && order <= target_order) {
          el.dataset.order = order - 1;
          this.animateToOrder(el, order - 1);
        }
      }
    }

    // set active order to be target order
    active.dataset.order = target_order;
    active.querySelector('.item-position').value = target_order;
  }

  setGridPos(el, order) {
    var abs_pos = this.getPos(order);
    TweenLite.set(el, {
      'top': abs_pos.top + 'px',
      'left': abs_pos.left + '%'
    });
  }

  _animateToOrder(el, order) {
    var new_pos = this.getPos(order);
    //animate to new order
    TweenMax.to(el, 0.2, {
      top: new_pos.top + 'px',
      left: new_pos.left + '%'
    });
  }


}


