import { Controller } from "stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
	static debounces = [ "onInput" ]

	connect() {
		useDebounce(this)
	}

	onInput() {
		this.nearestForm.requestSubmit()
	}

	onChange() {
		this.nearestForm.requestSubmit()
	}

	get nearestForm() {
		return this.element.tagName === "FORM" ? this.element : this.element.closest("form")
	}

	redirect(event) {
		const selectedPath = event.target.value;

		if (selectedPath) {
			window.location.href = selectedPath
		}
	}
}
