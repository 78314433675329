import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  copy() {
    navigator.clipboard.writeText(this.fieldTarget.value)

		if (typeof alertify !== "undefined") {
			alertify.notify('Copied!', '', 2);
		}
  }
}
