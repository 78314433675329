class ScriptLoader {
	static loadScript(id, callback) {
		// for start, if for some reason `callback` if not sent ... we just bail out, no need to
		// to any check or even load the script
		if (!callback) return

		// check if third-party script is declared
		if (!window.App.thirdParties[id]) {
			console.error("Unknown third-party script")
			return
		}

		// we will first check if we already have loaded this script
		const existingScript = document.getElementById(id)

		// if we don't have loaded the script yet, we will start by creating a DOM element that will
		// load the required script, then we will bind an event listener so that we load the callback
		// after the script gets loaded
		if (!existingScript) {
			const script = document.createElement("script")
			script.src = window.App.thirdParties[id]
			script.id = id
			script.dataset.loaded = false
			document.body.appendChild(script)
			this.#addEventListener(script, callback)
		} else {
			// if we already have the script, we also need to check if it was already loaded, if
			// not we need to attach another callback for this script
			const isLoaded = JSON.parse(existingScript.dataset.loaded)
			if (isLoaded) {
				callback()
			} else {
				this.#addEventListener(existingScript, callback)
			}
		}
	}

	static #addEventListener(script, callback) {
		// basically we are attaching a `load` event listener, after the load triggers we will mark
		// the script as loaded and execute the `callback` passed
		script.addEventListener("load", () => {
			script.dataset.loaded = true
			callback()
		}, { once: true })
	}
}

export default ScriptLoader
