import { Controller } from "stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
	static targets = [ "slider", "nav", "actions", "back", "close", "full"]

	initialize() {
		this.slideList = []
		this.lastSlide = null
		this.duplicateSlideIndex = null
		this.sliderTarget[this.identifier] = this
		this.destroySlider = this._destroySlider.bind(this)
	}

	connect() {
		document.addEventListener('turbo:before-cache', this.destroySlider );
	}

	disconnect() {
		document.removeEventListener('turbo:before-cache', this.destroySlider );
	}

	addNewItem(item, fullPath, expandable = false, form = false) {
		if (this.slideList.length == 0) {
			this._openSlider()
		} else {
			this.lastSlide = this.slideList[this.slideList.length - 1]

			this.duplicateSlideIndex = this.slideList.findIndex( element => {
				if (element.path === fullPath) {
					return true
				}
			})
		}

		// If rendering the same view or editable form, replace slider content
		if ((this.lastSlide) &&
				(this.lastSlide.path && this.lastSlide.path == fullPath ||
				this.lastSlide.form && this.lastSlide.form == form)) {

			this.lastSlide.element.innerHTML = item

			this.lastSlide.element.parentElement.scrollTo({
				top: 0,
				behavior: 'smooth'
			})

			this._updateActions()

		// If rendering view already open somewhere in the stack, close slide until target
		} else if ( this.duplicateSlideIndex != null && this.duplicateSlideIndex != -1) {
			const slideCount = this.duplicateSlideIndex - (this.slideList.length - 1)

			this._closeSlides(slideCount)
		// Otherwise add a new slide
		} else {
			var slide = document.createElement("li")
			slide.classList.add("side-slider__slide", "thu-bg-surface")

			var content = document.createElement("div")
			content.classList.add("side-slider__slide-content")
			content.innerHTML = item

			slide.appendChild(content)

			const navigation = this.navTarget.content.cloneNode(true)
			slide.insertBefore(navigation, slide.firstChild)

			this.sliderTarget.appendChild(slide)
			this.slideList.push({path: fullPath, element: content, form: form})

			this._updateActions()

			if (expandable) {
				this._showFull()
			} else {
				this._hideFull()
			}

			this.dispatch("opened", { detail: { slider: this.sliderTarget } })
		}

		// Now with forms on sliders, we need to make sure any open modals are closed
		this._closeModal()
	}

	async closeAndRefresh() {
		for (const slide of this.slideList.slice(0, -1)) {
			const response = await get(slide.path);

			if (response.ok) {
				response.html.then((result) => {
					slide.element.innerHTML = (new DOMParser()).parseFromString(result, "text/html").querySelector('div#space-container').innerHTML;
				})
			}
		}

		this.closeLastSlide()
	}

	closeSlider(immediate) {
		if (!this.isOpen) return;

		for (const slider of this.sliderTarget.childNodes) {
			slider.classList.add('is-exiting');
		}

		if (immediate) {
			this._clearSlider()
			this.dispatch("closed", { detail: { slider: this.sliderTarget } })
		} else {
			this.sliderTarget.lastChild.addEventListener('animationend', () => {
				this._clearSlider()
				this.dispatch("closed", { detail: { slider: this.sliderTarget } })
			})
		}

		this._hideNav()
		this._hideClose()

		this.isOpen = false
	}

	_clearSlider() {
		this.element.classList.remove("side-slider--open")

		this._removeFrames(this.sliderTarget)

		this.sliderTarget.innerHTML = ''
		this.sliderTarget.scrollTo(0,0)

		this.slideList = []
		this.lastSlide = null
		this.duplicateSlideIndex = null

		// Unlock the scroll and restore previous scroll position
		this.unlockScroll();
	}

	_destroySlider() {
		this.closeSlider(true)
	}

	_openSlider() {
		this.element.classList.add("side-slider--open")

		// Lock the scroll and save current scroll position
		this.lockScroll()

		this._showClose()

		this.isOpen = true
	}

	_updateActions() {
		this.fullTargets[this.fullTargets.length-1].href = this.slideList[this.slideList.length -1].path

		if (this.slideList.length > 1) {
			this._showNav()
		} else {
			this._hideNav()
		}
	}

	_closeSlides(count) {
		this.slideList.splice(count)

		const removedSlides = Array.from(this.sliderTarget.childNodes).slice(count)
		for (const slide of removedSlides) {
			this._removeFrames(slide)

			slide.classList.add('is-exiting');

			slide.addEventListener('animationend', () => {
				slide.parentNode.removeChild(slide)
			})
		}

		if (this.slideList.length == 0) {
			this.closeSlider()
		} else {
			this._updateActions()
		}
	}

	closeLastSlide(e) {
		if (e) {
			e.preventDefault()
		}

		this._removeFrames(this.sliderTarget.lastChild)

		this.slideList.pop()

		this.sliderTarget.lastChild.classList.add('is-exiting')
		this.sliderTarget.lastChild.addEventListener('animationend', () => {
			this.sliderTarget.removeChild(this.sliderTarget.lastChild)
		});

		if (this.slideList.length == 0) {
			this.closeSlider()
		} else {
			this._updateActions()
		}
	}

	_showNav() {
		this.backTarget.classList.remove('hidden')
	}

	_hideNav() {
		this.backTarget.classList.add('hidden')
	}

	_showClose() {
		this.closeTarget.classList.remove('hidden')
	}

	_hideClose() {
		this.closeTarget.classList.add('hidden')
	}

	_showFull() {
		this.fullTargets[this.fullTargets.length-1].classList.remove('hidden')
		this.actionsTargets[this.fullTargets.length-1].classList.remove('side-slider__navigation--hidden')
	}

	_hideFull() {
		this.fullTargets[this.fullTargets.length-1].classList.add('hidden')
		this.actionsTargets[this.fullTargets.length-1].classList.add('side-slider__navigation--hidden')
	}

	_removeFrames(target) {
		// Remove iframes, in case there's a video or audio embed playing somwhere...
		const iframe = target.querySelector('iframe')

		if( iframe ) {
			iframe.parentNode.removeChild(iframe)
		}
	}

	_closeModal() {
		const modal = document.querySelector('#modal_placeholder');

		if (modal && !modal.classList.contains('hidden')) {
			this.modalController.close()
		}
	}

	lockScroll() {
		// We only lock scroll if another component hasn't locked it yet
		if (!document.body.classList.contains('is-locked-scroll')) {

			// Add right padding to the body so the page doesn't shift
			// when we disable scrolling
			const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
			document.body.style.paddingRight = `${scrollbarWidth}px`;

			// Save the scroll position
			this.saveScrollPosition();

			// Add classes to body to fix its position
			document.body.classList.add('is-locked-scroll');

			// Add negative top position in order for body to stay in place
			document.body.style.top = `-${this.scrollPosition}px`;

			this.isLockingScroll = true;
		}
	}

	unlockScroll() {
		if (this.isLockingScroll ) {
			// Remove tweaks for scrollbar
			document.body.style.paddingRight = null;

			// Remove classes from body to unfix position
			document.body.classList.remove('is-locked-scroll');

			// Restore the scroll position of the body before it got locked
			this.restoreScrollPosition();

			// Remove the negative top inline style from body
			document.body.style.top = null;

			// Remove saved scroll position
			this.scrollPosition = null

			this.isLockingScroll = false
		}
	}

	saveScrollPosition() {
		this.scrollPosition = window.pageYOffset || document.body.scrollTop;
	}

	restoreScrollPosition() {
		document.documentElement.scrollTop = this.scrollPosition;
	}

	get modalController() {
		return this.application.getControllerForElementAndIdentifier(document.querySelector('#modal_placeholder').parentElement, "modal")
	}
}
