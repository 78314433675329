import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:render', this._setWindowScrollPosition);
  }

  disconnect() {
    document.removeEventListener('turbo:render', this._setWindowScrollPosition);
  }

  setScroll() {
    window.scrollPosition = window.scrollY;
  }

  _setWindowScrollPosition() {
    if (window.scrollPosition) {
      window.scrollTo(0, scrollPosition);
      window.scrollPosition = null;
    }
  }
}

